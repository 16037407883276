import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { GET_MODULES_BY_USER_CODES } from "../../../app/service/module.service";
import { GET_PARENT_BY_STUDENT, GET_USER_USER_CODE } from "../../../app/service/users.service";
import appConfig, { UI_BASE_URL } from '../../../config/constant';
import STUDENTIMG from '../../../institute-dashboard-assets/images/student-img.svg'
import SUBJECTCOMPLETE from '../../../institute-dashboard-assets/images/subj-completed-logo.svg'
import FATHERIMG from '../../../institute-dashboard-assets/images/father-icon.svg'
import MOTHERIMG from '../../../institute-dashboard-assets/images/mother-icon.svg'
import MNSLOGO from '../../../institute-dashboard-assets/images/mns_logo.png'
import ABHYAASLOGO from '../../../institute-dashboard-assets/images/Abyas-logo.svg'
import PARIKSHALOGO from '../../../institute-dashboard-assets/images/Pariksha-logo.svg'
import { useHistory } from "react-router-dom";
import { GET_SUBSCRIPTION_BY_USER_CODES } from "../../../app/service/user-subscription";
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from "../../../app/service/subscription.service";
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE, UPDATE_PAYMENT } from "../../../app/service/payment-service";
import { CREATE_MNS_USER_ASSIGN, GET_MNS_STUDENT_STATUS } from "../../../app/service/mns-service";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { CREATE_CCAVENUE_PAYMENT } from "../../../app/service/ccavenue.service";
import { APPLY_COUPON_CODE, UPDATE_APPLIED_COUPON_CODE } from "../../../app/service/promotions";
import moment from "moment";

function StudentDashboardList() {
    const Razorpay = useRazorpay();
    const [loading, setLoading] = useState(false);
    const [studentInfo, setStudentInfo] = useState<any>({});
    const UserCode = localStorage.getItem("user_code");
    const [parentList, setParentList] = useState<any>([]);
    const userRole = localStorage.getItem("user_role");
    const [moduleData, setModuleData] = useState<any>([]);
    const [subscription, setSubscription] = useState<any>({});
    const history = useHistory();
    const [moduleInfo, setModuleInfo] = useState<any>({});
    const [moduleName, setModuleName] = useState<any>("");
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(false);
    const [amount, setAmount] = useState<any>("");
    const [isExpired, setIsExpired] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [freeTrail, setFreeTrail] = useState<boolean>(false);
    const [couponData, setCouponData] = useState<any>({});
    const [couponCodeValue, setCouponCodeValue] = useState<any>("");
    const [couponLoading, setCouponLoading] = useState(false);
    const [showCouponDiv, setShowCouponDiv] = useState<boolean>(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const RAZORPAY_OPTIONS: RazorpayOptions = {
        key: appConfig.paymentKey,
        amount: (amount + "000"),
        currency: "INR",
        name: "IAMSAMARTH",
        description: "Subscription Fee",
        image: "",
        order_id: "",
        prefill: {
            name: moduleName,
            email: "",
            contact: "9999999999",
        },
        notes: {
            address: "Razorpay Corporate Office",
        },
        theme: {
            color: "#3399cc",
        },
    };
    useEffect(() => {
        // getUserDetails();


        // ccavenue //
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                const couponsData = JSON.parse(localStorage.getItem("cc_coupons_data") || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo, couponsData);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }
        fetchAllData();


    }, []);

    // 
    const fetchAllData = async () => {
        setLoading(true);
        try {
            const userDetails = await getUserDetails();
            await Promise.all([
                getParents(userDetails),
                getSubscriptionDetails(UserCode),
                getModulesByUserCode(UserCode, userRole, userDetails)
            ]);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const getUserDetails = async () => {
        try {
            const response: any = await GET_USER_USER_CODE(UserCode);
            if (response.status === 'fail') {
                toast.error(response.message);
                throw new Error(response.message);
            } else {
                const data = response.rows[0];
                if (data?.account_type === "TRIAL" && data?.trial_start_dt && data?.trial_end_dt) {
                    const trialStartDate = new Date(data?.trial_start_dt);
                    const trialEndDate = new Date(data?.trial_end_dt);
                    const currentDate = new Date();
                    const endEqualsCurrent = currentDate?.toDateString() === trialEndDate?.toDateString();
                    if (currentDate >= trialStartDate && (endEqualsCurrent || currentDate <= trialEndDate)) {
                        setFreeTrail(true);
                    }
                }
                setStudentInfo(data);
                return data;
            }
        } catch (error) {
            console.error('Error fetching user details:', error);
            throw error;
        }
    };

    const getParents = async (data: any) => {
        if (data?.guardian) {
            try {
                const guardian = data?.guardian ? data.guardian.replace(/[^a-zA-Z0-9,-_]/g, "") : "";
                const res: any = await GET_PARENT_BY_STUDENT(guardian);
                if (res.status === 'fail') {
                    toast.error(res.message);
                    throw new Error(res.message);
                } else {
                    const data = res.rows;
                    setParentList(data);
                }
            } catch (error) {
                console.error('Error fetching parent details:', error);
                throw error;
            }
        }
    };

    const getSubscriptionDetails = async (userCode: any) => {
        try {
            const response: any = await GET_SUBSCRIPTION_BY_USER_CODES(userCode);
            if (response.status === 'fail') {
                toast.error(response.message);
                throw new Error(response.message);
            } else {
                const data = response.data[0];
                if (new Date(data?.subscription_to) < new Date()) {
                    setIsExpired(true);
                }
                setSubscription(data);
            }
        } catch (error) {
            console.error('Error fetching subscription details:', error);
            throw error;
        }
    };

    const getModulesByUserCode = async (userCode: any, userRole: any, studentInfo?: any) => {
        try {
            const res: any = await GET_MODULES_BY_USER_CODES(userCode);
            if (res.status === 'fail') {
                toast.error(res.message);
                throw new Error(res.message);
            } else {
                let data = res;
                if (userRole !== "PARENT") {
                    data = res.filter((el: any) => el.module_code !== 'CAS-PARENT');
                }
                setModuleData(data);
            }
        } catch (error) {
            console.error('Error fetching modules:', error);
            throw error;
        }
    };


    const getModulesTrialExpired = (studentInfo: any, pendingModules: any) => {
        const modulesFreeTrial: string = studentInfo?.modules;
        if (modulesFreeTrial && pendingModules?.length > 0) {
            let modulesExpiredArray: string[] = [];

            pendingModules?.forEach((ele: any) => {
                modulesFreeTrial?.split(',')?.forEach((item: any) => {
                    if (ele?.module_code.trim() === item.trim()) {
                        modulesExpiredArray.push(item.trim());
                    }
                });
            });

            // Join the expired modules with ', ' and return the result
            const modulesExpired = modulesExpiredArray.join(', ');
            return modulesExpired ? modulesExpired : false;
        } else {
            return false;
        }
    }

    function openTest(data: any) {
        if (data.module_code === "MATHS") {
            localStorage.removeItem('setTimer');
            localStorage.setItem("backword_key", "false");
            getMNSStudentStatus(data);
        }
        else if (data?.module_code === "CAS") {
            history.push(`/student-dashboard/list/cas-test-instructions/${data.user_details['user_code']}/${data.module_code}`)
        }
        else if (data?.module_code === "ABHYAAS") {
            history.push(`/student-dashboard/list/abhyas/test-level-status/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);
        } else if (data?.module_code === "PARIKSHA") {
            // history.push(`/student-dashboard/list/pariksha/test-level-status/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);
            history.push(`/student-dashboard/list/pariksha/test-initiative/${data.uuid}/${data?.user_details?.user_code}/${studentInfo?.institute_school_code}`);

        }
    }

    function onClickPay(data: any) {
        setPaymentInfo({});
        setModuleInfo(data);
        setModuleName(data.module_name);
        getPaymentDetailsOfUser(data);
        removeCouponData();
        setCanShowPopup(true);
    }

    function getPaymentDetailsOfUser(data: any) {
        const payLoad = {
            "institute_school_code": studentInfo?.institute_school_code,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${studentInfo?.user_code}` }],
            "module_uuid": data?.uuid
        }
        setPaymentDetailsLoading(true);
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
                setPaymentDetailsLoading(false);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setAmount(data[0]?.total_amount);
                    }
                    setPaymentDetailsLoading(false);
                }
            }
        });

    }
    const handleclose = () => {
        setCanShowPopup(false);
    }
    const handlePayment = () => {
        createPayment(moduleInfo, paymentInfo);
        localStorage.setItem("cc_module_info", JSON.stringify(moduleInfo));
        localStorage.setItem("cc_student_info", JSON.stringify(studentInfo));

    }
    function createPayment(moduleInfo: any, paymentInfo: any) {
        const payload = {
            school_code: studentInfo?.institute_school_code,
            user_ids: paymentInfo?.user_code,
            sub_amount: couponData?.final_amount ? Math.round(couponData?.final_amount) : paymentInfo?.total_amount
        }
        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        if (couponData?.final_amount == 0 && Object.keys(couponData?.rule_applied).length > 0) {
                            updateSubscription(res.txn_id, { "message": "SUM AMOUNT ZERO" }, paymentInfo, "success", studentInfo, moduleInfo, couponData);
                        } else {
                            onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                        }
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }

    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)

        if (amount > 0) {
            createCcavenuePayment(amount, txn_id);

        } else {
            onUpdatePayment(txn_id, { "message": "SUM AMOUNT ZERO" }, "success", moduleInfo);
            if (amount > 0) {
                updateSubscription(txn_id, { "message": "SUM AMOUNT ZERO" }, paymentInfo, "success");
            }
        }
    }

    function getAssignModule(paymentInfo: any, moduleData: any, studentInfo?: any) {

        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: studentInfo?.institute_school_code,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Successfully assigned  module!");
                setLoading(true);
                getModulesByUserCode(UserCode, userRole);
                removeItemsFromeLocalStorage();
                setCanShowPopup(false);
                setLoading(false);
            }
        });
    }

    function onUpdatePayment(transactionId: string, data: any, status: string, moduleData: any) {

        const payload = {
            txn_id: transactionId,
            "module_code": moduleData.module_code ? moduleData.module_code : '',
            "module_uuid": '',
            "txn_details": data,
            "status": status
        }

        UPDATE_PAYMENT(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    if (status === "success") {
                        toast.success('Payment Updated Successfully');
                        getAssignModule(paymentInfo, moduleData, studentInfo);
                        setCanShowPopup(false);
                    }
                }
            }
        });
    }
    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any, couponData?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": paymentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getSubscriptionDetails(UserCode);
                        getAssignModule(paymentInfo, moduleInfo, studentInfo);
                        toast.success('Subscription Added Successfully');
                        if (couponData) {
                            updateAppliedCoupon(transactionId, couponData);
                        }
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
            if (couponData) {
                updateAppliedCoupon(transactionId, couponData);
            }
        }
    }

    function getMNSStudentStatus(data: any) {

        let payload = {
            module_uuid: data.uuid,
            school_code: studentInfo?.institute_school_code,
            user_code: data.user_details['user_code']
        }
        setLoading(true);
        GET_MNS_STUDENT_STATUS(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                if (res) {
                    const data = res;
                    let status = Array.isArray(data.assesment_test) ? data.assesment_test.length > 0 : Object.keys(data.assesment_test).length !== 0;
                    if (status) {
                        history.push(`/student-dashboard/list/mns/view/${payload.module_uuid}/${payload.user_code}/${studentInfo?.institute_school_code}`);
                    } else {
                        history.push(`/student-dashboard/list/mns/test-level/${payload.module_uuid}/${payload.user_code}/${studentInfo?.institute_school_code}`);
                    }
                }
            }
        });
    }

    // function getSubscriptionTotal(price: any, amount: any) {
    //     let subscriptionPrice: any = price > 0 ? parseInt(price) : '';
    //     let moduleAmount: any = amount > 0 ? parseInt(amount) : '';
    //     if ((subscriptionInfo.students && subscriptionInfo['students'][0].payment_status === 'not-completed') || (subscriptionInfo.payment_status && subscriptionInfo.payment_status === "not-completed")) {
    //         return `${subscriptionPrice + moduleAmount}`
    //     } else {
    //         return `${moduleAmount}`
    //     }

    // }

    function onClickViewReports(item: any) {
        if (item.module_code === "CAS") {
            history.push(`/student-dashboard/list/reports/student/${item.uuid}/${item.user_details.user_code}`)
        }

        if (item.module_code === "MATHS") {
            // history.push(`/math-report-pdf/${item.user_details.user_code}`);

            history.push(`/student-dashboard/list/maths/reports/student/${item.user_details.user_code}`);

        }
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/student-dashboard/list`,
            cancel_url: `${UI_BASE_URL}/student-dashboard/list`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_module_info', 'cc_coupons_data'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };

    const getModuelFreeTrial = (moduleCode: string) => {
        const modulesFreeTrial: string = studentInfo?.modules;
        if (moduleCode && modulesFreeTrial && freeTrail) {
            const modulesArray = modulesFreeTrial?.split(',').map((item: any) => item?.trim());
            return modulesArray?.includes(moduleCode);
        } else {
            return false;
        }
    }

    function onApplyCouponValue(e: any) {
        if (e) {
            setCouponCodeValue(e.target.value);
        } else {
            setCouponData({});
            setCouponCodeValue("");
        }

    }
    function applyCoupon() {
        const payload = {
            promo_code: couponCodeValue?.replace(/\s/g, ''),
            students_count: 1,
            payable_amount: paymentInfo?.total_amount,
            institute_code: studentInfo?.organization_code,
            school_code: studentInfo?.institute_school_code
        }
        if (payload?.promo_code) {
            setCouponData({});
            setShowCouponDiv(false);
            setCouponLoading(true);
            APPLY_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setCouponLoading(false);
                } else {
                    let data = res
                    if (Object.keys(data?.rule_applied).length > 0) {
                        setShowCouponDiv(true);
                        localStorage.setItem("cc_coupons_data", JSON.stringify(data));
                        data.coupon_code = payload?.promo_code;
                        setCouponData(data);
                    }
                    setCouponLoading(false);
                }
            });
        }

    }
    const removeCouponData = () => {
        setCouponData({});
        setCouponCodeValue("");
        localStorage.removeItem("cc_coupons_data");
        setShowCouponDiv(false);
    }
    function updateAppliedCoupon(txnId: any, couponData?: any) {
        const payload = {
            promotion_uuid: couponData?.promotion_uuid ? couponData?.promotion_uuid : "",
            promo_code_uuid: couponData?.promocode_uuid ? couponData?.promocode_uuid : "",
            applied_for_user_code: UserCode,
            applied_sponsor_uuid: "",
            applied_dt: todayDate,
            applied_rules: couponData,
            status: 2
        }
        if ((couponData?.final_amount > 0 || couponData?.final_amount == 0) && Object.keys(couponData?.rule_applied).length > 0) {
            setLoading(true);
            UPDATE_APPLIED_COUPON_CODE(payload).then((res: any) => {
                if (res.status === 'fail') {
                    toast.error(res?.err);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
        }
    }
    const capitalizeWords = (name: string): string => {
        return name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    };
    return (
        <div>

            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog subscription-payment-dialog" role="document">
                    {/* <div className="modal-dialog " role="document"> */}
                    <div className="modal-content subscription-payment-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Payment Details</h5>
                            <button type="button" className="close popup-close" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            {paymentDetailsLoading &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!paymentDetailsLoading && <div className=" custom-scroll table-responsive">
                                <table className="table table_new_ui fixed-table-header mb-0">
                                    <thead>
                                        <tr>
                                            <th>Student Name</th>
                                            <th>Module Name</th>
                                            <th>Subscription Price</th>
                                            <th>Module Price</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>
                                    {Object.keys(paymentInfo).length > 0 && <tbody>
                                        <tr>
                                            <td>{paymentInfo?.name}</td>
                                            <td>{(moduleInfo.module_code === "CAS" || moduleInfo.module_code === "MATHS") ? moduleInfo?.module_code : moduleInfo?.module_code?.charAt(0).toUpperCase() + moduleInfo?.module_code?.slice(1).toLowerCase()}</td>
                                            <td>{(paymentInfo?.subscription_payment_status === "completed") ? <span>-</span> : <span>{parseInt(paymentInfo?.subscription_price)}</span>}</td>
                                            <td>{paymentInfo?.module_price}</td>
                                            <td>{paymentInfo?.total_amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Total Amount to Pay</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style={{ fontWeight: 'bold' }}>{paymentInfo?.total_amount}</td>
                                        </tr>
                                    </tbody>}
                                </table>
                                {Object.keys(paymentInfo).length == 0 && <p className="text-center f12 mt-3">No Data Found</p>}
                            </div>}
                            {paymentInfo?.total_amount > 0 &&
                                <div className={`row mt-1 justify-content-center ${Object.keys(couponData).length == 0 && "mb-5"}`} >
                                    <div className='col-lg-6 col-md-8 col-11 d-flex'>
                                        <input className='form-control form-control-lg f14 mt-4' placeholder=' Coupon Code' type='text' value={couponCodeValue} onChange={(e) => onApplyCouponValue(e)}></input>
                                        {couponLoading &&
                                            <div className="text-center mt-4 ms-1">
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only ">Loading...</span>
                                                </div>
                                            </div>
                                        }
                                        {!couponLoading && <button type="button" className="btn btn-primary cursor-pointer mt-4 pt-1" onClick={applyCoupon}>
                                            Apply</button>}
                                        {Object.keys(couponData).length > 0 && <button type="button" className="btn btn-success cursor-pointer mt-4 pt-1 ms-4" onClick={removeCouponData}>Remove</button>}
                                    </div>
                                </div>
                            }
                            {(couponLoading && showCouponDiv) &&
                                <div className="text-center p-5">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            }
                            {!couponLoading &&
                                <div>
                                    {(showCouponDiv && parseInt(paymentInfo?.total_amount) > 0) &&
                                        <div className="row   mt-4 mb-5  justify-content-md-center" >
                                            <div className="col-md-10 col-lg-7 col-12  py-3 px-4 rounded-3" style={{ backgroundColor: '#e6e6e6', margin: '0px' }}>
                                                <h3 className="dkpi-hd f18 mb-3 text-center">Coupon Applied</h3>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className="mb-0">Coupon Code</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.coupon_code}
                                                    </div>
                                                </div>

                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className="mb-0">Payable Amount</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.payable_amount}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">

                                                    <div className="col ">
                                                        <p className="mb-0">Discount Percentage</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.discount_percentage?.toFixed()}%
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between mb-1">
                                                    <div className="col">
                                                        <p className=" mb-0">Discount Amount</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        {couponData?.discount_amount?.toFixed()}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-between  mt-2">
                                                    <div className="col">
                                                        <p className="text-gray f18 fw-bold mb-0"> Final Amount  to Pay</p>
                                                    </div>
                                                    <div className="col">
                                                        <span className="me-3">:</span>
                                                        <span className="f16 fw-bold"> {Math.round(couponData?.final_amount)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }

                        </div>

                        <div className="modal-footer">
                            {(Object.keys(paymentInfo).length > 0) && <button type="button" className="btn btn-primary cursor-pointer" onClick={handlePayment}>{((parseInt(paymentInfo?.total_amount)) > 0 && couponData?.final_amount !== 0) ? 'Pay Now' : 'Assign'}</button>}
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                        <li className="breadcrumb-item"><span>Dashboard</span></li>
                    </ol>
                </nav>
                <div className="border-bottom pb-3">
                    <div className="d-md-flex sc-profile-details">
                        <div className="mb-3 mb-md-0">
                            <div className="sc-profile-img py-0">
                                <img
                                    src={studentInfo?.profile_img ? `${appConfig.FILE_UPLOAD_URL}${studentInfo.profile_img.charAt(0) === "/" ? studentInfo.profile_img.substring(1) : studentInfo.profile_img}` : STUDENTIMG}
                                    alt=""
                                    className="pr-logo"
                                />
                            </div>
                        </div>
                        <div className="sc-profile-text ps-md-4">
                            <ol className="breadcrumb border-0 mb-0 p-0">
                                {studentInfo?.name && subscription?.payment_status === "completed" ? (isExpired ? <li className="breadcrumb-item text-notsubc pe-1">Subscription Expired</li> : <li className="breadcrumb-item text-subc pe-1">Subscribed</li>) :
                                    <li className="breadcrumb-item text-notsubc">Not Yet Subscribed</li>}
                            </ol>
                            <h2 className="mt-0 mb-2">{studentInfo?.name && capitalizeWords(studentInfo?.name)}</h2>
                            <p className="m-0 sc-profile-contact">
                                <span>{studentInfo?.mobile_number}</span> {studentInfo?.email_id && <span> | </span>} <span>{studentInfo?.email_id} </span>
                            </p>
                            <p className="m-0 sc-profile-addrs">
                                <span>{studentInfo?.state}</span> {studentInfo?.district && <span> | </span>} <span>{studentInfo?.district}</span>{studentInfo?.city_village && <span> | </span>}
                                <span>{studentInfo?.city_village}</span>
                            </p>
                        </div>
                    </div>
                </div>
                {studentInfo?.guardian && <h3 className="mt-4 mb-3 section-hd">Parent Details</h3>}
                <div className={`${studentInfo?.guardian && "border-bottom"} pb-3 mb-4`}>
                    <div className="row">
                        {parentList?.map((data: any) => {
                            return (
                                <div className="col-md-6">
                                    <div className="d-flex sc-profile-details small mb-4">
                                        <div className="sc-profile-img pt-0">
                                            {data?.gender === "female" ? <img
                                                src={data.profile_img ? `${appConfig.FILE_UPLOAD_URL}${data.profile_img.charAt(0) === "/" ? data.profile_img.substring(1) : data.profile_img}` : MOTHERIMG}
                                                alt=""
                                                className="pr-image"
                                            /> : <img
                                                src={data.profile_img ? `${appConfig.FILE_UPLOAD_URL}${data.profile_img.charAt(0) === "/" ? data.profile_img.substring(1) : data.profile_img}` : FATHERIMG}
                                                alt=""
                                                className="pr-image"
                                            />}
                                        </div>
                                        <div className="sc-profile-text ps-4">
                                            <p className="sc-profile-deg mb-1">{data.gender === "female" ? <span>Mother</span> : <span>Father</span>}</p>
                                            <h2 className="m-0">{data?.name?.charAt(0).toUpperCase() + data?.name?.slice(1)}</h2>
                                            <p className="m-0 sc-profile-contact">
                                                <span>{data?.mobile_number}</span> {data?.email_id && <span> | </span>} <span>{data?.email_id} </span>
                                            </p>
                                            <p className="m-0 sc-profile-addrs">
                                                <span>{data?.state}</span> {data?.district && <span> | </span>} <span>{data?.district}</span>{(data?.city_village && data?.district) && <span> | </span>}
                                                <span>{data?.city_village}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>)
                        })}

                    </div>
                </div>
                <div className="row">
                    {moduleData?.map((data: any, i: number) => {
                        return (<div className="col-md-4 col-lg-3 mb-3" key={i}>
                            <div className={`module-card h-100 position-relative ${(data?.module_code === "MATHS" || data?.module_code === "PARIKSHA") && "card-two"} ${data?.module_code === "ABHYAAS" && "card-three"}`}>
                                {data.module_code === "MATHS" && <img className=" module_logo_img" src={MNSLOGO} alt="" />}
                                {data.module_code === "ABHYAAS" && <img className="  module_logo_img" src={ABHYAASLOGO} alt="" />}
                                {data.module_code === "PARIKSHA" && <img className=" module_logo_img" src={PARIKSHALOGO} alt="" />}
                                <div>
                                    {(data.module_code === "CAS" || data.module_code === "MATHS") && <h2 className={`module-title mb-0`}>{(data.module_code === "CAS") ? data?.module_code : data?.module_code?.charAt(0).toUpperCase() + data?.module_code?.slice(1).toLowerCase()}</h2>}
                                    {data.module_code === "ABHYAAS" && <h2 className={`module-title mb-0`}>Abhyaas</h2>}
                                    {data?.module_code === "PARIKSHA" && <h2 className={`module-title mb-0`}>Parikshā</h2>}
                                    {data.module_code === "CAS" && <h5 className="mb-3">Career Aspiration<sup>TM</sup> Survey</h5>}
                                    {data.module_code === "MATHS" && <h5 className="mb-3">गणित</h5>}
                                    {data.module_code === "ABHYAAS" && <h5 className="mb-3">अभ्यास</h5>}
                                    {data?.module_code === "PARIKSHA" && <h5 className="mb-3">परीक्षा</h5>}
                                </div>
                                <p className="mb-2 ">
                                    {/* Payment - <label >{(freeTrail && getModuelFreeTrial(data?.module_code)) ? <span className="free_trial_btn f14">Free Trial</span> : (data.amount_status === "PENDING" || data.amount_status === null) && <span className="text-inprogess"> Pending </span>} {data.amount_status === "COMPLETED" && <span className="text-completed"> Paid</span>}</label> */}
                                    Payment - <label > {data.amount_status === "COMPLETED" ? <span className="text-completed"> Paid</span> : (freeTrail && getModuelFreeTrial(data?.module_code)) ? <span className="free_trial_btn f14">Free Trial</span> : (data.amount_status === "PENDING" || data.amount_status === null) && <span className="text-inprogess"> Pending </span>} </label>

                                </p>
                                {((data.amount_status === "COMPLETED") || (freeTrail && getModuelFreeTrial(data?.module_code))) ? <p className="m-0">
                                    Status - <label>{(data.module_status === "inprogress" || data.module_status === null) && <span className="text-inprogess">In Progress <span onClick={() => openTest(data)} className="btn btn-continue ms-3">
                                        Continue test</span></span>}{data.module_status === "completed" && <span className="text-completed"> Completed</span>}</label>
                                </p> : <p className="m-0"><span className="btn btn-pay" onClick={() => onClickPay(data)}>Pay Now</span></p>
                                }
                                {data?.module_status === "completed" && <img
                                    src={SUBJECTCOMPLETE}
                                    alt=""
                                    className="module-completed-logo"
                                />}
                                {(data?.module_status === "completed" || (data?.module_code === "MATHS" && data.amount_status === "COMPLETED") || (data?.module_code === "MATHS" && freeTrail && getModuelFreeTrial(data?.module_code))) && <p className="m-0 "> <span className="btn btn-continue mt-2 " id={`${data?.module_code}_VIEW`} onClick={() => onClickViewReports(data)}> View Report </span></p>}
                            </div>
                        </div>)
                    })}
                    {moduleData?.length === 0 && <p className="mt-4 f14">Modules are not Assigned</p>}
                </div>

            </div>}

        </div>
    )

}
export default StudentDashboardList;