import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { LOGIN } from "../../app/service/login.service";
import LogoImg from "../../assets/images/iamsamarth-Logo-Final.svg";
import Logos from "../../assets/images/eliments.svg";
import FormBuilder from "../../components/form-builder";
import {
  FormControlError,
  FormField,
  FormValidators,
} from "../../components/form-builder/model/form-field";
import {
  FormValidator,
  GetControlIsValid,
} from "../../components/form-builder/validations";
import { ToastContainer, toast } from "react-toastify";
import { GET_SCHOOL_BY_SCHOOL_CODE } from "../../app/service/school.service";
import { GET_INSTITUTE_BY_INSTITUTE_ID } from "../../app/service/institute.service";
import {
  GET_INDIVIDUAL_BY_USER_CODE,
  GET_USER_BY_CODE,
  GET_USER_USER_CODE,
} from "../../app/service/users.service";
import { GET_ACCESS_PERMISSIONS } from "../../app/service/access-permissions.service";

export const LoginPage = () => {
  const [loginValidationErrors, setLoginValidationErrors] = useState<
    FormControlError[]
  >([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loginData, setLoginData] = useState<any>({});
  const [formData, setFormData] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const formValidations = [
    new FormField("user_name", [FormValidators.REQUIRED]),
    new FormField("password", [FormValidators.REQUIRED]),
  ];

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleInput = (data: any) => {
    setLoginData(data);
    setFormData({ ...data.value });
    const errors: any = FormValidator(formValidations, data.value);
    setLoginValidationErrors(errors);
  };

  const onClickLogin = () => {
    setIsFormSubmitted(true);
    const logData = { ...loginData.value };
    const loginsData = {
      user_name: logData?.user_name,
      password: logData?.password
    }
    const errors: FormControlError[] = FormValidator(
      formValidations,
      loginsData
    );
    setLoginValidationErrors(errors);
    if (errors.length < 1) {
      setLoading(true);
      LOGIN(loginsData).then((res: any) => {
        if (res.status === "fail") {
          setErrorMessage(res.message);
          setLoading(false);
        }
        else {
          if (res.first_time_login === 1) {
            localStorage.setItem("user-date-of-birth", res.user_info.date_of_birth);
            localStorage.setItem("user_name", res.user_info.name);
            localStorage.setItem("token", res.token);
            localStorage.setItem("user_code", res.user_code);
            localStorage.setItem("first_time_login", res.first_time_login);
            history.push(`/set-password/${loginsData.user_name}`);
          } else {
            const user_info = res.user_info;
            let userRole = user_info.user_role;
            if (userRole === "MASTERADMIN" || userRole === "SAMARTH_ADMIN") {
              userRole = "SUPER_ADMIN";
            }
            localStorage.setItem("user_name", user_info.name);
            localStorage.setItem("role_name", user_info.role_details.role_name);
            localStorage.setItem("user_role", userRole);
            localStorage.setItem("user_code", res.user_code);
            localStorage.setItem("token", res.token);
            localStorage.setItem("organization_name", res.organization_name);

            if (user_info.user_role === "PARENT" || user_info.user_role === "STUDENT") {

              if (user_info.user_role === "PARENT") {
                getStudentWiseDetails(
                  user_info.user_role,
                  user_info.institute_school_code,
                  res.user_code
                );

              } else if (user_info.user_role === "STUDENT") {

                if (user_info.type === "individual") {
                  getIndividualUserDetails(res.user_code);
                  localStorage.setItem("user_type", user_info.type);
                }
                else {
                  getStudentWiseDetails(
                    user_info.user_role,
                    user_info.institute_school_code,
                    res.user_code
                  );

                }
              }
            } else {
              getPermissions(user_info.user_role, res);
            }

          }
        }
      });
    }
  };

  const getPermissions = (roleCode: any, userInfo: any) => {
    setLoading(true);
    GET_ACCESS_PERMISSIONS(roleCode).then((res: any) => {
      if (res) {
        if (res.status === "fail") {
          toast.error(res.message);
          setLoading(false);
        } else {
          const preparedData = [];
          preparedData.push(res);
          const data = res;
          if (res.length > 0) {
            filterData(data, userInfo);
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
      }
    });
  };

  const filterData = (originalData: any, res: any) => {
    let finalData = [];
    let parentData = [];
    let childData = [];
    for (let index = 0; index < originalData.length; index++) {
      let element: any = originalData[index];
      if (element.object_code.includes("-")) {
        childData.push(element);
      } else {
        element.child = [];
        element.canShowChildren = false;
        parentData.push(element);
      }
    }
    for (let index = 0; index < childData.length; index++) {
      const element = childData[index];
      if (element.object_code.includes("-")) {
        let splitTitle = element.object_code.split("-");
        for (let i = 0; i < parentData.length; i++) {
          let ele: any = parentData[i];
          if (ele.object_code == splitTitle[0]) {
            ele.child.push(element);
          }
        }
      } else {
        parentData.push(element);
      }
    }


    let accessPermissionsData: any = parentData.filter((el) => el.p_read === 1 || el.p_write);
    localStorage.setItem("access_objects_data", JSON.stringify(accessPermissionsData));
    finalData = originalData.filter((el: any) => el.p_read === 1 || el.p_write);
    getFeaturePermissionStatus(res, finalData);
  };

  function getSchoolDetails(userRole: any, schoolCode: any) {
    setLoading(true);
    GET_SCHOOL_BY_SCHOOL_CODE(schoolCode).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.message);
        setLoading(false);
      } else {
        setLoading(false);
        if (res.rows.length > 0) {


          if (
            userRole === "PRINCIPAL" ||
            userRole === "VICE_PRINCIPAL" ||
            userRole === "SCHOOL_ADMIN" ||
            userRole === "TEACHER" ||
            userRole === "FULL_TIME_MENTOR"
          ) {
            history.push(`school-dashboard/view`);
          }
          // else if (userRole === "TEACHER") {
          //   history.push(`teacher-dashboard/view`);
          // }
          else {
            history.push(
              `dashboard/school/info/${res.rows[0].id}/principallist/${res.rows[0].school_code}`
            );
          }
        }
      }
    });
  }

  function getStudentWiseDetails(
    userRole: string,
    schoolCode: any,
    userCode: any
  ) {
    setLoading(true);
    if (schoolCode) {
      GET_SCHOOL_BY_SCHOOL_CODE(schoolCode).then((res: any) => {
        if (res.status === "fail") {
          toast.error(res.message);
          setLoading(false);
        } else {
          setLoading(false);
          if (res.rows.length > 0) {
            toast.success('Login successfully');
            if (userRole === "PARENT") {
              localStorage.setItem("user_code", userCode);
              localStorage.setItem("user_id", res.rows[0].id);
              localStorage.setItem("school_code", res.rows[0].school_code);

              history.push("/parent-dashboard/view");

            } else if (userRole === "STUDENT") {
              history.push("/student-dashboard/list");
            }
          }
        }
      });
    } else {
      if (userRole === "PARENT") {
        toast.success('Login successfully');
        history.push("/parent-dashboard/view");
      }
    }
  }

  function getUserDetails(userRole: string, schoolCode: any, userCode: any) {
    setLoading(true);
    GET_USER_BY_CODE(userCode).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.message);
        setLoading(false);
      } else {
        setLoading(false);
        if (res.rows.length > 0) {
          const data = JSON.stringify(res.rows[0].FTM_schools);
          localStorage.setItem("FTM_school_codes", data);
          history.push("/dashboard/school/list");
        }
      }
    });
  }

  function getIndividualUserDetails(user_code: any) {
    setLoading(true);
    GET_INDIVIDUAL_BY_USER_CODE(user_code).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.message);
        setLoading(false);
      } else {
        setLoading(false);
        if (res.rows.length > 0) {
          localStorage.setItem(
            "institute_code",
            res.rows[0].institute_school_code
          );
          history.push("/student-dashboard/list");
        }
      }
    });
  }

  function getInstituteById(schoolCode: any) {
    setLoading(true);
    GET_INSTITUTE_BY_INSTITUTE_ID(schoolCode).then((res: any) => {
      if (res.status === "fail") {
        toast.error(res.message);
        setLoading(false);
      } else {
        if (res.rows.length > 0) {
          setLoading(false);
          history.push(`institute-dashboard/dashboard`);

        }
      }
    });
  }

  /* feature permission scenario */

  function getFeaturePermissionStatus(res: any, finalData: any) {

    if (finalData.length > 0) {
      toast.success('Login successfully');
      const user_info = res.user_info;
      if (
        user_info.user_role === "PRINCIPAL" ||
        user_info.user_role === "VICE_PRINCIPAL" ||
        user_info.user_role === "SCHOOL_ADMIN" ||
        user_info.user_role === "TEACHER"
      ) {
        localStorage.setItem("school_name", res.school_name);
        localStorage.setItem(
          "institute_code",
          user_info.institute_school_code
        );
        getSchoolDetails(
          user_info.user_role,
          user_info.institute_school_code
        );
      }

      else if (user_info.user_role === "FULL_TIME_MENTOR") {
        getUserDetails(
          user_info.user_role,
          user_info.institute_school_code,
          res.user_code
        );
      } else if (user_info.user_role === "MATHS_MANAGER") {
        history.push("/dashboard/module/list");
      } else if (user_info.user_role == "PRINCIPAL") {
        // history.push("/dahsboard/institute-dashboard")
      } else {
        if (
          user_info.user_role === "CHAIRMAN" ||
          user_info.user_role === "GENERAL_SECRETARY" ||
          user_info.user_role === "INSTITUTE_ADMIN"
        ) {
          localStorage.setItem(
            "institute_code",
            user_info.institute_school_code
          );

          localStorage.setItem(
            "organization_code",
            user_info.institute_school_code
          );
          // history.push("dashboard/school/list");
          getInstituteById(user_info.institute_school_code);
        } else {
          history.push("/dashboard/view");
          setLoading(false);
        }
      }
      let userRole = user_info.user_role;
      if (userRole === "MASTERADMIN" || userRole === "SAMARTH_ADMIN") {
        userRole = "SUPER_ADMIN";
      }
      localStorage.setItem("user_name", user_info.name);
      localStorage.setItem("role_name", user_info.role_details.role_name);
      localStorage.setItem("user_role", userRole);
      localStorage.setItem("user_code", res.user_code);
      localStorage.setItem("token", res.token);
    } else {
      toast.error("user has no access permissions");
    }

  }





  const getInputValid = (control: string) => {
    const value = GetControlIsValid(loginValidationErrors, control);
    return value;
  };

  function onClickForgotPassword() {
    history.push(`/forgot-password`);
  }
  const onClickInstitutSignUp = () => {
    history.push(`/organization-signup`);

  }

  function onClickEyeIcon() {
    setShowPassword(!showPassword);
  }

  function onClickIndividualUser() {
    // history.push(`/individual-student-signin`);
    history.push(`/individual-student-signup`);
  }
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onClickLogin();
    }
    if (event.key === ' ') {
      event.preventDefault(); // Prevent spacebar key press
    }
  };


  return (
    <div style={{ overflowY: "auto", height: "100vh" }}>
      <section className="d-flex justify-content-md-center align-items-center login-block-wraper">
        <div>
          <div className="bg-white border border-gray rounded-16 p-3 p-lg-5 shadow-sm login-block">
            <div className="row justify-content-md-center login-row">
              <div
                className="col col-md-5 text-center text-lg-start"
                style={{ paddingTop: "100px" }}
              >
                <img src={LogoImg} alt="" className="login-logo" />
              </div>

              <div className="col-md-7 login-fileds">
                <FormBuilder onUpdate={handleInput}>
                  <form>
                    <h2 className="login-hd mt-3 mb-3 mb-md-5 text-center text-lg-start">
                      Login
                    </h2>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control fw-light" id="user_name"
                        name="user_name"
                        placeholder="Please enter user Id"
                        onKeyPress={handleKeyPress}
                      />
                      <label htmlFor="floatingInput">User name</label>
                      {isFormSubmitted && !getInputValid("user_name") && (
                        <p className="text-danger">Please enter user name</p>
                      )}
                    </div>
                    <div className="form-floating">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control fw-light" id="password"
                        name="password"
                        placeholder="password"
                        onKeyPress={handleKeyPress}
                      />
                      <span
                        className="eye-icon cursor-pointer"
                        onClick={() => onClickEyeIcon()}
                      >
                        <i
                          className={
                            showPassword ? "bi bi-eye-fill" : "bi bi-eye-slash"
                          }
                        ></i>
                      </span>
                      <label htmlFor="floatingPassword">Password</label>
                      {isFormSubmitted && !getInputValid("password") && (
                        <p className="text-danger">Please enter password</p>
                      )}
                    </div>
                  </form>
                </FormBuilder>
                <div className="text-center mb-1 mt-2" style={{ color: "red" }}>
                  {errorMessage}
                </div>
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="mt-4 mb-3 text-center text-lg-start">
                    <a
                      className="btn btn-primary btn-sm px-5 rounded-12 fw-light cursor-pointer" id="login"
                      onClick={onClickLogin}
                    >
                      Login
                    </a>
                    <a
                      className="d-inline-block ms-2 f12 forgot-color cursor-pointer mt-2  ms-4"
                      onClick={() => onClickIndividualUser()}
                    >
                      Student Sign Up
                    </a>

                    <div className="mt-2">
                      <a
                        className="d-inline-block ms-2 f12 forgot-color cursor-pointer"
                        onClick={() => onClickForgotPassword()}
                      >
                        Forgot password..?
                      </a>
                      <a
                        className="d-inline-block ms-2 f12 forgot-color cursor-pointer ms-5"
                        onClick={() => onClickInstitutSignUp()}
                      >
                        Institution Sign Up
                      </a>
                    </div>

                  </div>
                )}

              </div>
              {/* } */}
            </div>
          </div>
          <div className="text-center position-relative">
            <p className="f12 ps-2 forgot-color mt-2">Version 2.2.0</p>
          </div>
          <div
            className="login-elements text-center"
            style={{ marginTop: "3rem" }}
          >
            <img src={Logos} alt="" className="login-elements-icons" />
          </div>
        </div>
      </section>
      <footer className="text-center f12 text-dgray">
        <div className="container">
          &copy; 2023-24  <a href="http://iamsamarth.com" className="text-dgray text-decoration-none">iamsamarth.com</a>. All Rights Reserved | <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none">Terms and Conditions</a> |
          <a href="http://iamsamarth.com/privacy-policy/" className="text-dgray text-decoration-none ms-1">Privacy Policy</a>
        </div>
      </footer>
    </div>
  );
};
