import React, { useEffect, useState } from 'react'
import FormBuilder from '../../../components/form-builder'
import { FormValidator, GetControlIsValid } from '../../../components/form-builder/validations';
import { FormControlError, FormField, FormValidators } from '../../../components/form-builder/model/form-field';
import { Link, useHistory, useParams } from 'react-router-dom';
import { PopupData } from '../../../redux/app-utility/model/popup-data';
import { AppUtilityActions } from '../../../redux/app-utility/action';
import { connect } from 'react-redux';
import { CREATE_INSTITUTE, GET_INSTITUTE_BY_ID, UPDATE_INSTITUTE } from '../../../app/service/institute.service';
import { LOOK_UP_DATA } from '../../../app/service/lookup.service';
import { CREATE_SCHOOL_USER, UPLOAD_LOGO } from '../../../app/service/school.service';
import { ToastContainer, toast } from 'react-toastify';
import appConfig from '../../../config/constant';
import TimeKeeper from 'react-timekeeper';
import errorMessages from '../../../components/error-messages';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { DateComponent } from '../../../components/date';
import { GET_STUDENT_SUBSCRIPTION_PRICE_LIST } from '../../../app/service/subscription.service';
import ProfileImageCrop from '../../../components/image-crop/crop';
import { GET_RUN_CRON_JOB } from '../../../app/service/cron-job';

interface Props {
    updatePopup?: (data: PopupData) => void;
    instituteId: (value: any) => void;
}

export const InstituteFormPage: React.FC<any> = (props: Props) => {
    let { id } = useParams<{ id: string }>();
    const instituteId = Number(id) || 0;
    const [usersData, setUsersData] = useState<any>({});
    const [formData, setFormData] = useState<any>({});
    const [currentInstituteData, setCurrentInstituteData] = useState<any>({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isFormEdit, setIsFormEdit] = useState(false);
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const [statesData, setStatesData] = useState<any>([]);
    const [districtData, setDistrictData] = useState<any>([]);
    const [cityData, setCityData] = useState<any>([]);
    const [instituteLogo, setInstituteLogo] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [imageLoader, setImageLoader] = useState(false);
    const [todayDate, setTodayDate] = useState(moment().format('YYYY-MM-DD'));
    const [createDate, setCreateDate] = useState(moment().format('YYYY-MM-DD'));
    const [checkedStatus, setCheckedStatus] = useState<any>();
    const [email, setEmail] = useState(false);
    const [startTime, setStartTime] = useState('08:30am');
    const [endTime, setEndTime] = useState('03:30pm');
    const [startShowTime, setStartShowTime] = useState(false);
    const [endShowTime, setEndShowTime] = useState(false);
    const [secondarySchool, setSecondarySchool] = useState(false);
    const [juniorCollege, setJuniorCollege] = useState(false);
    const [contactNumber, setContactNumber] = useState(false);
    const [faxNumber, setFaxNumber] = useState(false);
    const [GSTNumber, setGSTNumber] = useState(false);
    const [primarySchool, setPrimarySchool] = useState(false);
    const [pincodeNumber, setPincodeNumber] = useState(false);
    const [panNumber, setPanNumber] = useState(false);
    const [gstNumber, setGstNumber] = useState(false);
    const [seniorCollege, setSeniorCollege] = useState(false);
    const [profCollege, setProfCollege] = useState(false);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [organizationNameError, setOrganizationNameError] = useState('');
    const [organizationShortNameError, setOrganizationShortNameError] = useState('');
    const [displayNameError, setDisplayNameError] = useState('');
    const [registrationNumberError, setRegistrationNumberError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [webAddressError, setWebAddressError] = useState('');
    const [allStateBlock, setAllStateBlock] = useState<any>({});
    const [blockData, setBlockData] = useState<any>([]);
    const [cityVillagesError, setCityVillagesError] = useState('');
    const [amountError, setAmountError] = useState('');
    const [blockError, setBlockError] = useState('');
    const [areaError, setAreaError] = useState('');
    const [isRegistrationDateError, setIsRegistrationDateError] = useState<any>({});
    const [checkedUserMechanismStatus, setCheckedUserMechanismStatus] = useState<any>();
    const [disabled, setDisabled] = useState(true);
    const [subscriptionPrice, setSubscriptionPrice] = useState('');
    const userRole = localStorage.getItem("user_role");
    const history = useHistory();
    const formValidations = [
        new FormField('name_of_organization', [FormValidators.REQUIRED]),
        new FormField('short_name', [FormValidators.REQUIRED]),
        new FormField('display_name', [FormValidators.REQUIRED]),
        new FormField('email_id', [FormValidators.REQUIRED]),
        new FormField('contact_number', [FormValidators.REQUIRED]),
        new FormField('registration_number', [FormValidators.REQUIRED]),
        new FormField('fax_number', []),
        new FormField('date_of_registration', []),
        new FormField('timings', []),
        new FormField('address', [FormValidators.REQUIRED]),
        new FormField('taluka', []),
        new FormField('district', [FormValidators.REQUIRED]),
        new FormField('state', [FormValidators.REQUIRED]),
        new FormField('pin_code', [FormValidators.REQUIRED]),
        new FormField('area', [FormValidators.REQUIRED]),
        new FormField('city_village', []),
        new FormField('num_of_primary_school', [FormValidators.REQUIRED]),
        new FormField('num_of_secondary_school', [FormValidators.REQUIRED]),
        new FormField('num_of_junior_college', [FormValidators.REQUIRED]),
        new FormField('num_of_senior_college', [FormValidators.REQUIRED]),
        new FormField('num_of_professional_colleges', [FormValidators.REQUIRED]),
        new FormField('gst_number', []),
        new FormField('pan_number', []),
        new FormField('website_url', [FormValidators.REQUIRED]),
        new FormField('logo_url', [FormValidators.REQUIRED]),
        new FormField('cas_report_view', []),
    ];


    useEffect(() => {
        const userRole = localStorage.getItem("user_role");
        setLoading(true);
        LOOK_UP_DATA().then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
            } else {
                setLoading(false);
                setStatesData(res.states);
            }
            if (instituteId > 0) {
                setIsFormEdit(true);
                GET_INSTITUTE_BY_ID(instituteId).then((response: any) => {
                    if (response.status === 'fail') {
                        toast.error(response.message);
                    } else {
                        const data = response.rows[0];
                        setInstituteLogo(data.logo_url);
                        data.num_of_primary_school = String(data.num_of_primary_school);
                        data.num_of_secondary_school = String(data.num_of_secondary_school);
                        data.num_of_junior_college = String(data.num_of_junior_college);
                        data.num_of_senior_college = String(data.num_of_senior_college);
                        data.num_of_professional_colleges = String(data.num_of_professional_colleges);
                        setCurrentInstituteData(data);
                        getStatesData(res.states, data);
                        setCheckedStatus(data.cas_report_view);
                        if (data.timings) {
                            setStartTime(data.timings.split("-")[0]);
                            setEndTime(data.timings.split("-")[1]);
                        }
                        if (data.date_of_registration) {
                            const date_of_registration: any = new Date(data.date_of_registration);
                            setTodayDate(date_of_registration);
                        }
                        if (data.created_at) {
                            const created_at: any = new Date(data.created_at);
                            setCreateDate(created_at);
                        }

                        if (data.access) {
                            setCheckedUserMechanismStatus(data.access);
                        }
                    }
                });
            }
        });

        getSubscriptionList()
    }, []);

    function getStatesData(states: any, data: any) {
        if (states.length > 0) {
            const preparedData = states.filter((el: any) => el.state === data.state);
            if (preparedData.length > 0) {
                setDistrictData(preparedData[0].districts);
                setCityData(preparedData[0].cities);
                onLoadBlocks(data.district, preparedData[0]?.block);
            }
        }
    }

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(validationErrors, control);
        return value;
    }

    const handleclose = () => {
        setCanShowPopup(false);
        setCurrentInstituteData(usersData);
    }

    const handleSave = () => {
        createInstitute();
    }

    function createInstitute() {
        setLoading(true);
        const data = usersData;
        data.num_of_primary_school = parseInt(data.num_of_primary_school);
        data.num_of_secondary_school = parseInt(data.num_of_secondary_school);
        data.num_of_junior_college = parseInt(data.num_of_junior_college);
        data.num_of_senior_college = parseInt(data.num_of_senior_college);
        data.num_of_professional_colleges = parseInt(data.num_of_professional_colleges);
        CREATE_INSTITUTE(data).then(res => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setLoading(false);
                setCanShowPopup(false);
                data.num_of_primary_school = data.num_of_primary_school.toString();
                data.num_of_secondary_school = data.num_of_secondary_school.toString();
                data.num_of_junior_college = data.num_of_junior_college.toString();
                data.num_of_senior_college = data.num_of_senior_college.toString();
                data.num_of_professional_colleges = data.num_of_professional_colleges.toString();
                setCurrentInstituteData(data);
            } else {
                props.instituteId(res.organization_code);
                setCanShowPopup(false);
                toast.success("Institute Created Successfully");
                cronJob();
                setLoading(false);
            }
        });
    }
    const cronJob = async () => {
        const res: any = await GET_RUN_CRON_JOB();
        if (res) {
            if (res?.status === 'fail') {

            } else {

            }
        }
    }

    function handleSubmit() {
        setIsFormSubmitted(true);
        const data = usersData.value ? { ...usersData.value } : { ...currentInstituteData };
        data.logo_url = instituteLogo;
        data.date_of_registration = todayDate ? moment(todayDate).format('YYYY-MM-DD') : null;
        data.cas_report_view = checkedStatus;
        data.timings = `${startTime}-${endTime}`;
        data.subscription_price = subscriptionPrice;
        delete data.year;
        delete data.day;
        delete data.month;
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);
        Object.keys(data).forEach(function (key) {
            if (typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        });
        let preData = removeEmptyStringKeys(data);
        if (errors.length <= 1 && !secondarySchool && !juniorCollege && !contactNumber && !primarySchool &&
            !pincodeNumber && !panNumber && !seniorCollege && !profCollege && !gstNumber && !email && !faxNumber
            && onChangeOrganizationName(data.name_of_organization)
            && onChangeOrganizationShortName(data.short_name)
            && onChangeDisplayName(data.display_name)
            && onChangeRegistrationNumber(data.registration_number)
            && onChangeAddress(data.address)
            && onChangeArea(data.area)
            && onChangeWebAddress(data.website_url) && !cityVillagesError) {
            setUsersData(preData);
            setCanShowPopup(true);
        } else {
            if (!organizationNameError && !onChangeOrganizationName(data.name_of_organization)) {
                setOrganizationNameError('Please enter institution');
            }
            if (!organizationShortNameError && !onChangeOrganizationShortName(data.short_name)) {
                setOrganizationShortNameError('Please enter institution short name');
            }
            if (!displayNameError && !onChangeDisplayName(data.display_name)) {
                setDisplayNameError('Please enter display name');
            }
            if (!registrationNumberError && !onChangeRegistrationNumber(data.registration_number)) {
                setRegistrationNumberError('Please enter registration number');
            }
            if (!addressError && !onChangeAddress(data.address)) {
                setAddressError('Please enter address');
            }
            if (!areaError && !onChangeArea(data.area)) {
                setAreaError('Please enter area / locality');
            }
            if (!webAddressError && !onChangeWebAddress(data.website_url)) {
                setWebAddressError('Please enter website address');
            }

        }

    }

    function handleUpdate() {
        setIsFormSubmitted(true);
        const data = usersData.value ? { ...usersData.value } : { ...currentInstituteData };
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);
        if (data.subscription_price === "") {
            data.subscription_price = null;
        }
        data.num_of_primary_school = parseInt(data.num_of_primary_school);
        data.num_of_secondary_school = parseInt(data.num_of_secondary_school);
        data.num_of_junior_college = parseInt(data.num_of_junior_college);
        data.num_of_senior_college = parseInt(data.num_of_senior_college);
        data.num_of_professional_colleges = parseInt(data.num_of_professional_colleges);
        data.timings = `${startTime}-${endTime}`;
        data.logo_url = instituteLogo;
        data.id = instituteId;
        data.cas_report_view = checkedStatus;
        data.date_of_registration = todayDate ? moment(todayDate).format('YYYY-MM-DD') : null;;
        data.contact_number = data.contact_number.toString();
        delete data.status;
        delete data.created_at;
        delete data.updated_at;
        delete data.year;
        delete data.day;
        delete data.month;
        delete data.access;
        if (errors.length <= 1 && !secondarySchool && !juniorCollege && !contactNumber && !primarySchool &&
            !pincodeNumber && !panNumber && !seniorCollege && !profCollege && !gstNumber && !email && !faxNumber
            && onChangeOrganizationName(data.name_of_organization)
            && onChangeOrganizationShortName(data.short_name)
            && onChangeDisplayName(data.display_name)
            && onChangeRegistrationNumber(data.registration_number)
            && onChangeAddress(data.address)
            && onChangeArea(data.area)
            && onChangeWebAddress(data.website_url)) {
            data.access = checkedUserMechanismStatus;
            setLoading(true);
            let preData = removeEmptyStringKeys(data);

            UPDATE_INSTITUTE(preData).then(res => {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    toast.success("Institute Updated Successfully");
                    history.push('/dashboard/institute/list');
                    setLoading(false);
                }
            });
        } else {
            if (!organizationNameError && !onChangeOrganizationName(data.name_of_organization)) {
                setOrganizationNameError('Please enter institution');
            }
            if (!organizationShortNameError && !onChangeOrganizationShortName(data.short_name)) {
                setOrganizationShortNameError('Please enter institution short name');
            }
            if (!displayNameError && !onChangeDisplayName(data.display_name)) {
                setDisplayNameError('Please enter display name');
            }
            if (!registrationNumberError && !onChangeRegistrationNumber(data.registration_number)) {
                setRegistrationNumberError('Please enter registration number');
            }
            if (!addressError && !onChangeAddress(data.address)) {
                setAddressError('Please enter address');
            }
            if (!areaError && !onChangeArea(data.area)) {
                setAreaError('Please enter area / locality');
            }
            if (!webAddressError && !onChangeWebAddress(data.website_url)) {
                setWebAddressError('Please enter website address');
            }

        }
    }
    function removeEmptyStringKeys(obj: any) {
        return Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => key !== "")
        );
    }
    const handleInput = (data: any) => {
        data.value = { ...currentInstituteData, ...data.value };
        setUsersData(data);
        const errors: any = FormValidator(formValidations, data.value);
        setValidationErrors(errors);
    }

    const handleStateChange = (e: any) => {
        const data = { ...usersData.value };
        data.state = e.target.value;
        const preparedData = statesData.filter((el: any) => el.state === e.target.value);
        setDistrictData(preparedData[0].districts);
        setCityData(preparedData[0].cities);
        setAllStateBlock(preparedData[0]?.block);
        if (currentInstituteData) {
            currentInstituteData.state = e.target.value;
        }
    }

    const handleDistrictChange = (e: any) => {
        setBlockData([]);
        const data = { ...usersData.value };
        data.district = e.target.value;
        if (currentInstituteData) {
            currentInstituteData.district = e.target.value;
        }
        onLoadBlocks(e.target.value, allStateBlock);
    }

    const onLoadBlocks = (e: any, blocks: any) => {
        Object.keys(blocks).find(key => {
            if (key === e) {
                setBlockData(blocks[key]);
                return;
            }
        });
    }
    const handleBlockChange = (e: any) => {
        const data = { ...usersData.value };
        data.taluka = e.target.value;
        if (currentInstituteData) {
            currentInstituteData.taluka = e.target.value;
        }
    }


    function handleUploadLogo(e: any) {
        if (e.target.files && e.target.files[0]) {
            const fileType = e.target.files[0].name.split('.').pop();
            // if (fileType == "gif" || fileType == "png" || fileType == "bmp" || fileType == "jpeg" || fileType == "jpg" || fileType == "svg") {
            if (fileType == "jpeg" || fileType == "jpg") {
                const formData = new FormData();
                formData.append('file', e.target.files[0], e.target.files[0].name);
                uploadLogo(formData);
            } else {
                toast.error("Valid file type .jpg, .jpeg only");
            }
        }

    }

    function uploadLogo(formdata: any) {
        setImageLoader(true);
        UPLOAD_LOGO(formdata).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
                setImageLoader(false);
            } else {
                setInstituteLogo(res.url);
                setImageLoader(false);
            }
        });
    }

    const handleFaxNumberChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.fax_number = e.target.value;
            setFaxNumber(false);
        } else {
            data.fax_number = e.target.value;
            setFaxNumber(true);
        }

        if (currentInstituteData) {
            currentInstituteData.fax_number = e.target.value.replace(/\D+/g, '');
        }
    }

    const handlePinCodeChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;

        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 6) {
            data.pin_code = e.target.value;
            setPincodeNumber(false);
        } else {
            data.pin_code = e.target.value;
            setPincodeNumber(true);
        }

        if (currentInstituteData) {
            currentInstituteData.pin_code = e.target.value.replace(/\D+/g, '');
        }
    }

    const handlePanChange = (e: any) => {
        const data = { ...usersData.value };
        // /[A-Z]{5}[0-9]{4}[A-Z]{1}$/

        // if ((/[^0-9a-zA-Z]/.test(e.target.value) || e.target.value.length < 10 || e.target.value.length > 10))
        if (!(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/.test(e.target.value)) && e.target.value) {
            setPanNumber(true);
        } else {
            data.pan_number = e.target.value;
            setPanNumber(false);
        }

        if (currentInstituteData) {
            currentInstituteData.pan_number = e.target.value;
        }
    }

    const handlePrimarySchoolChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.num_of_primary_school = e.target.value;
            setPrimarySchool(false);
        } else {
            data.num_of_primary_school = e.target.value;
            setPrimarySchool(true);
        }

        if (currentInstituteData) {
            currentInstituteData.num_of_primary_school = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleSecondarySchoolChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.num_of_secondary_school = e.target.value;
            setSecondarySchool(false);
        } else {
            data.num_of_secondary_school = e.target.value;
            setSecondarySchool(true);
        }

        if (currentInstituteData) {
            currentInstituteData.num_of_secondary_school = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleJuniorChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.num_of_junior_college = e.target.value;
            setJuniorCollege(false);
        } else {
            data.num_of_junior_college = e.target.value;
            setJuniorCollege(true);
        }

        if (currentInstituteData) {
            currentInstituteData.num_of_junior_college = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleSeniorChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.num_of_senior_college = e.target.value;
            setSeniorCollege(false);
        } else {
            data.num_of_senior_college = e.target.value;
            setSeniorCollege(true);
        }

        if (currentInstituteData) {
            currentInstituteData.num_of_senior_college = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleProfessionalChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            data.num_of_professional_colleges = e.target.value;
            setProfCollege(false);
        } else {
            data.num_of_professional_colleges = e.target.value;
            setProfCollege(true);
        }

        if (currentInstituteData) {
            currentInstituteData.num_of_professional_colleges = e.target.value.replace(/\D+/g, '');
        }
    }

    const handleGSTChange = (e: any) => {
        const data = { ...usersData.value };

        if (!(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(e.target.value))) {
            setGstNumber(true);
        } else {
            data.gst_number = e.target.value;
            setGstNumber(false);
        }

        if (currentInstituteData) {
            currentInstituteData.gst_number = e.target.value;
        }

    }

    const handleReportStatusChange = (e: any) => {

        const status = e.target.checked === true ? 1 : 0;
        setCheckedStatus(status);

        if (currentInstituteData) {
            const status = e.target.checked === true ? 1 : 0;
            currentInstituteData.cas_report_view = e.target.checked === true ? 1 : 0;
            setCheckedStatus(status);

        }
    }

    const handleEmailChange = (e: any) => {
        const data = { ...usersData.value };
        // const re = /[a-z0-9\._%+!$&*=^|~#%'`?{}/\-]+@([a-z0-9\-]+\.){1,}([a-z]{2,16})/;
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

        if (e.target.value === '' || re.test(e.target.value)) {
            data.email_id = e.target.value;
            setEmail(false);
        } else {
            data.email_id = e.target.value;
            setEmail(true);
        }

        if (currentInstituteData) {
            currentInstituteData.email_id = e.target.value;
        }
    }

    const handleContactChange = (e: any) => {
        const data = { ...usersData.value };
        const re = /^[0-9]{10,11}$/;
        if ((e.target.value === '' || re.test(e.target.value)) && e.target.value.length === 10) {
            data.contact_number = e.target.value;
            setContactNumber(false);
        } else {
            data.contact_number = e.target.value;
            setContactNumber(true);
        }

        if (currentInstituteData) {
            currentInstituteData.contact_number = e.target.value;
        }
    }

    const onDateChange = (dates: any) => {
        setTodayDate(todayDate);
        if (dates.error) {
            setIsRegistrationDateError(dates);
        } else {
            setTodayDate(dates.date);
        }
    };

    function onTodayDate(theDate: any, years: any) {
        return new Date(theDate.getTime() - years * 24 * 60 * 60 * 1000);
    }

    const onChangeOrganizationName = (event: any) => {
        setOrganizationNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setOrganizationNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setOrganizationNameError('Please enter institution name');
        }
    }

    const onChangeOrganizationShortName = (event: any) => {
        setOrganizationShortNameError('');
        const reg = /^[A-Za-z0-9]+(?:[ _-][A-Za-z0-9]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setOrganizationShortNameError('Institution short name cannot  contain spaces and special  characters');
            } else {
                return true;
            }
        } else {
            setOrganizationShortNameError('Please enter institution short name');
        }
    }

    const onChangeDisplayName = (event: any) => {
        setDisplayNameError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setDisplayNameError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setDisplayNameError('Please enter display name');
        }
    }

    const onChangeRegistrationNumber = (event: any) => {
        setRegistrationNumberError('');
        const reg = /^[0-9]{1,15}$/;
        if (event) {

            if (!reg.test(event.trim())) {
                setRegistrationNumberError('Registration number must be numbers and min 1 and maximum 15 characters');
            } else {
                return true;
            }
        } else {
            setRegistrationNumberError('Please enter registration number');
        }
    }

    const onChangeAddress = (event: any) => {
        setAddressError('');
        if (event) {
            if (event.trim().length <= 2) {
                setAddressError('Address minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setAddressError('Address maximum 256 characters');

            } else {
                return true;
            }
        } else {
            setAddressError('Please enter Address');
        }
    }

    const onChangeWebAddress = (event: any) => {
        setWebAddressError('');
        if (event) {
            if (event?.trim().length <= 2) {
                setWebAddressError('Address minum 3 characters');
            } else if (event.trim().length >= 256) {
                setWebAddressError('Address maximum 256 characters');

            } else {
                return true;
            }
        } else {
            setWebAddressError('Please enter Address');
        }
    }

    const onChangeBlock = (event: any) => {
        setBlockError('');
        if (event) {
            if (event.trim().length <= 2) {
                setBlockError('Block minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setBlockError('Block maximum 256 characters');

            } else {
                return true;
            }
        } else {
            setBlockError('Please enter Block');
        }
    }

    const onChangeArea = (event: any) => {
        setAreaError('');
        if (event) {
            if (event.trim().length <= 2) {
                setAreaError('Area / Locality minimum 3 characters');
            } else if (event.trim().length >= 256) {
                setAreaError('Area / Locality maximum 256 characters');

            } else {
                return true;
            }
        } else {
            setAreaError('Please enter area / locality');
        }
    }

    const onChangeCityVillage = (event: any) => {
        setCityVillagesError('');
        const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
        if (event) {
            if (!reg.test(event.trim())) {
                setCityVillagesError('Enter only alphabets');
            } else {
                return true;
            }
        } else {
            setCityVillagesError('');
        }
    }

    const handleUserMechanism = (e: any) => {
        if (currentInstituteData) {
            const status = e.target.checked === true ? "ENABLED" : "DISABLED";
            currentInstituteData.access = status;
            setCheckedUserMechanismStatus(status);
        }
    }
    const onChangeAmount = (event: any) => {
        setAmountError('');
        const reg = /^[0-9]*$/;
        if (event !== 0 || event !== '') {
            if (!reg.test(event)) {
                setAmountError('Amount only numbers');
            } else {
                return true;
            }
        } else {
            setAmountError('Please enter amount');
        }
    }
    function getSubscriptionList() {
        setLoading(true);
        GET_STUDENT_SUBSCRIPTION_PRICE_LIST("SUBSCRIPTION_PRICE", 0).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setSubscriptionPrice(data[0].data.subscription_price)
                    setLoading(false);
                }
            }
        });
    }

    function editSubscriptionPrice() {
        // let edit = true;
        setDisabled(!disabled)

    }

    return (
        <div className="row border-top border-primary py-3">
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            <div className={`modal fade ${canShowPopup ? 'show' : 'false'}`} style={{ display: (canShowPopup ? "block" : 'none') }} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Alert</h5>
                            <button type="button" className="close popup-close cursor-pointer" data-dismiss="modal" aria-label="Close" onClick={handleclose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                “Kindly recheck details on the form. Once saved, they cannot be changed later”
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary cursor-pointer" data-dismiss="modal" onClick={handleclose}>Back</button>
                            <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
            {!loading && <div>
                <FormBuilder onUpdate={handleInput}>
                    <form>
                        <div className="row custom-form">
                            <div className="row" style={{ paddingLeft: '15px', fontWeight: 'bold', fontSize: '18px' }}>
                                Institute
                            </div>
                            <hr />
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Name of Education Society /Trust /Institution</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" onChange={(e) => onChangeOrganizationName(e.target.value)} name="name_of_organization" defaultValue={currentInstituteData?.name_of_organization} placeholder="Please enter institution name" />
                                    {/* {isFormSubmitted && !getInputValid('name_of_organization') && <p className="text-danger">Please fill the field</p>} */}
                                    {organizationNameError && <p className="text-danger">{organizationNameError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Short form of Institution</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="short_name" onChange={(e) => onChangeOrganizationShortName(e.target.value)} defaultValue={currentInstituteData?.short_name} placeholder="Please enter short name" />
                                    {organizationShortNameError && <p className="text-danger">{organizationShortNameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Display Name</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="display_name" onChange={(e) => onChangeDisplayName(e.target.value)} defaultValue={currentInstituteData?.display_name} placeholder="Please enter display name" />
                                    {displayNameError && <p className="text-danger">{displayNameError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Email ID</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="email_id" value={currentInstituteData?.email_id} onChange={(e) => { handleEmailChange(e) }} placeholder="Please enter email" />
                                    {email && <p className="text-danger">{errorMessages.email}</p>}
                                    {isFormSubmitted && !getInputValid('email_id') && <p className="text-danger">Please enter email</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Contact Number ( Mobile / Landline)</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="contact_number" maxLength={10} value={currentInstituteData?.contact_number} onChange={(e) => { handleContactChange(e) }} placeholder="Please enter mobile number" />
                                    {contactNumber && <p className="text-danger">Length of contact number is 10 digits, Enter proper value</p>}
                                    {isFormSubmitted && !getInputValid('contact_number') && <p className="text-danger">Please contact number</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">Fax Number (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="fax_number" maxLength={10} value={currentInstituteData?.fax_number} onChange={(e) => { handleFaxNumberChange(e) }} placeholder="Please enter fax number" />
                                    {faxNumber && <p className="text-danger">The length of the FAX number is 10 digits, Enter a proper value</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Registration Number</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="registration_number" onChange={(e) => onChangeRegistrationNumber(e.target.value)} defaultValue={currentInstituteData?.registration_number} placeholder="Please enter registration number" />
                                    {/* {isFormSubmitted && !getInputValid('registration_number') && <p className="text-danger">Please fill the field</p>} */}
                                    {registrationNumberError && <p className="text-danger">{registrationNumberError}</p>}

                                </div>
                            </div>
                            {isFormEdit && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Date of Registration</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    {currentInstituteData.date_of_registration &&
                                        <DateComponent onDate={onDateChange} yearDiff={0} idDisable={true} defaultData={currentInstituteData.date_of_registration}></DateComponent>}
                                    {!currentInstituteData.date_of_registration && <DateComponent idDisable={true} defaultData={todayDate} onDate={onDateChange} yearDiff={0}></DateComponent>}
                                    {/* {isFormSubmitted && !todayDate && !isRegistrationDateError?.error && <p className="text-danger">Please select date</p>} */}
                                    {/* {isRegistrationDateError?.error && !todayDate && <p className="text-danger">{isRegistrationDateError?.error}</p>} */}
                                </div>
                            </div>}
                            {/* <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">Created On</label>
                                    {currentInstituteData.created_at &&
                                        <DateComponent yearDiff={0} idDisable={true} defaultData={currentInstituteData.created_at}></DateComponent>}
                                    {!currentInstituteData.created_at && <DateComponent idDisable={true} defaultData={createDate} yearDiff={0}></DateComponent>}
                                </div>
                            </div> */}
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-3">Office Timings </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <br />
                                    {startShowTime &&
                                        <div className='timings-view'>
                                            <TimeKeeper
                                                time={startTime}
                                                onChange={(newTime) => setStartTime(newTime.formatted12)}
                                                onDoneClick={() => setStartShowTime(false)}
                                                switchToMinuteOnHourSelect
                                            />
                                        </div>
                                    }
                                    {!startShowTime &&
                                        <input className="form-control time-range cursor-pointer" type="text" placeholder={startTime} onClick={() => setStartShowTime(true)} />
                                    }
                                    {endShowTime &&
                                        <div className='timings-view'>
                                            <TimeKeeper
                                                time={endTime}
                                                onChange={(newTime) => setEndTime(newTime.formatted12)}
                                                onDoneClick={() => setEndShowTime(false)}
                                                switchToMinuteOnHourSelect
                                            />
                                        </div>
                                    }
                                    {!endShowTime &&
                                        <input className="form-control time-range cursor-pointer" type="text" placeholder={endTime} onClick={() => setEndShowTime(true)} />
                                    }
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Head Quarter Address</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="address" onChange={(e) => onChangeAddress(e.target.value)} defaultValue={currentInstituteData?.address} placeholder="Please enter address" />
                                    {addressError && <p className="text-danger">{addressError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">State</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select name="state" className="form-select form-select-lg mb-3" value={currentInstituteData?.state} onChange={(e) => { handleStateChange(e) }}>
                                        <option value="">Select state</option>
                                        {statesData.map((name: any, i: number) => (
                                            <option key={i} value={name.state}>
                                                {name.state}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('state') && <p className="text-danger">Please select state</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">District</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <select name="district" className="form-select form-select-lg mb-3" value={currentInstituteData?.district} onChange={(e) => { handleDistrictChange(e) }}>
                                        <option value="">Select district</option>
                                        {districtData.map((name: any, i: number) => (
                                            <option key={i} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {isFormSubmitted && !getInputValid('district') && <p className="text-danger">Please select district</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Block</label>
                                    {/* <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span> */}
                                    <select name="taluka" className="form-select form-select-lg mb-3" value={currentInstituteData?.taluka} onChange={(e) => { handleBlockChange(e) }}>
                                        <option value="">Select block</option>
                                        {blockData.map((name: any, i: number) => (
                                            <option key={i} value={name}>
                                                {name}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <input className="form-control form-control-lg" type="text" name="taluka" defaultValue={currentUsersData?.taluka} onChange={(e) => onChangeBlock(e.target.value)} placeholder="Please Enter Block" /> */}
                                    {isFormSubmitted && !getInputValid('taluka') && <p className="text-danger">Please select block</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Area / Locality</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="area" onChange={(e) => onChangeArea(e.target.value)} defaultValue={currentInstituteData?.area} placeholder="Please enter area" />
                                    {/* {isFormSubmitted && !getInputValid('area') && <p className="text-danger">Please fill the field</p>} */}
                                    {areaError && <p className="text-danger">{areaError}</p>}

                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">City/Village (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" name="city_village" onChange={(e) => onChangeCityVillage(e.target.value)} defaultValue={currentInstituteData?.city_village} placeholder="Please enter village" />
                                    {cityVillagesError && <p className="text-danger">{cityVillagesError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">PIN Code</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="pin_code" maxLength={6} value={currentInstituteData?.pin_code} onChange={(e) => { handlePinCodeChange(e) }} placeholder="Please enter PIN code" />
                                    {pincodeNumber && <p className="text-danger">{errorMessages.pincode}</p>}
                                    {isFormSubmitted && !getInputValid('pin_code') && <p className="text-danger">Please enter pin code</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-2">GST Number (Optional)</label>
                                    <input className="form-control form-control-lg" type="text" maxLength={15} name="gst_number" onChange={(e) => { handleGSTChange(e) }} value={currentInstituteData?.gst_number} placeholder="Please enter GST number" />
                                    {gstNumber && <p className="text-danger">The length of the GST number is 15 digits, Enter a proper value</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">PAN Number</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}></span>
                                    <input className="form-control form-control-lg" type="text" name="pan_number" maxLength={10} value={currentInstituteData?.pan_number} onChange={(e) => { handlePanChange(e) }} placeholder="Please enter PAN number" />
                                    {panNumber && <p className="text-danger">{errorMessages.pancard}</p>}
                                    {/* {isFormSubmitted && !getInputValid('pan_number') && <p className="text-danger">Please enter pan number</p>} */}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Website Address</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="website_url" defaultValue={currentInstituteData?.website_url} onChange={(e) => { onChangeWebAddress(e) }} placeholder="Please enter website url" />
                                    {/* {isFormSubmitted && !getInputValid('website_url') && <p className="text-danger">Please enter website address</p>} */}
                                    {webAddressError && <p className="text-danger">{webAddressError}</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Number of Primary School (1 to 5) </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="num_of_primary_school" value={currentInstituteData?.num_of_primary_school} onChange={(e) => { handlePrimarySchoolChange(e) }} placeholder="Please enter  number" />
                                    {primarySchool && <p className="text-danger">{errorMessages.number_of_primary_school}</p>}
                                    {isFormSubmitted && !getInputValid('num_of_primary_school') && <p className="text-danger">Please enter 0 or any positive number</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Number of Secondary School(6 to 10)</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="num_of_secondary_school" value={currentInstituteData?.num_of_secondary_school} onChange={(e) => { handleSecondarySchoolChange(e) }} placeholder="Please enter  number" />
                                    {secondarySchool && <p className="text-danger">{errorMessages.number_of_secondary_school}</p>}
                                    {isFormSubmitted && !getInputValid('num_of_secondary_school') && <p className="text-danger">Please enter 0 or any positive number</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Number of Junior College(11, 12)</label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="num_of_junior_college" value={currentInstituteData?.num_of_junior_college} onChange={(e) => { handleJuniorChange(e) }} placeholder="Please enter  number" />
                                    {juniorCollege && <p className="text-danger">{errorMessages.number_of_junior_colleges}</p>}
                                    {isFormSubmitted && !getInputValid('num_of_junior_college') && <p className="text-danger">Please enter 0 or any positive number</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Number of Senior College </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="num_of_senior_college" value={currentInstituteData?.num_of_senior_college} onChange={(e) => { handleSeniorChange(e) }} placeholder="Please enter number" />
                                    {seniorCollege && <p className="text-danger">{errorMessages.number_of_senior_colleges}</p>}
                                    {isFormSubmitted && !getInputValid('num_of_senior_college') && <p className="text-danger">Please enter 0 or any positive number</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label mb-0">Number of Professional Colleges </label>
                                    <span style={{ color: 'red', fontSize: '22px', paddingLeft: '10px' }}>*</span>
                                    <input className="form-control form-control-lg" type="text" name="num_of_professional_colleges" value={currentInstituteData?.num_of_professional_colleges} onChange={(e) => { handleProfessionalChange(e) }} placeholder="Please enter  number" />
                                    {profCollege && <p className="text-danger">{errorMessages.number_of_professional_colleges}</p>}
                                    {(isFormSubmitted && !getInputValid('num_of_professional_colleges')) && <p className="text-danger">Please enter 0 or any positive number</p>}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">CAS Report Accessible</label>
                                    <br />
                                    <label className="switch">
                                        <input type="checkbox" name="cas_report_view" value={currentInstituteData?.checkedStatus} checked={currentInstituteData?.cas_report_view === 1} onChange={(e) => { handleReportStatusChange(e) }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            {instituteId > 0 && <div className="col-md-4">
                                <div className="mb-4">
                                    <label className="form-label">User Access</label><br />
                                    <label className="switch">
                                        <input type="checkbox" name="access" value={currentInstituteData?.access} checked={currentInstituteData?.access === "ENABLED"} onChange={(e) => { handleUserMechanism(e) }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>}
                            {(instituteId > 0 && userRole === "SUPER_ADMIN") && <div className='col-md-4'>
                                <label className="form-label mb-0">Subscription Price</label>
                                <input className="form-control form-control-lg no-spinner" type="number" name="subscription_price" onChange={(e) => onChangeAmount(e.target.value)} defaultValue={currentInstituteData?.subscription_price} placeholder="Please enter amount" disabled={disabled} style={{ backgroundColor: disabled ? "#E8E8E8" : "white" }} />
                                <span className="eye-icon cursor-pointer">
                                    <i className="fa fa-pencil" style={{ fontSize: "20px" }} onClick={editSubscriptionPrice}></i>
                                    {/* <i className="fa fa-edit"></i> */}
                                </span>
                                {amountError && <p className="text-danger">{amountError}</p>}
                            </div>}
                            <div className="col-md-4 col-12">
                                <div className="mb-4 d-flex justify-content-center">
                                    <ProfileImageCrop uploadLogo={uploadLogo}
                                        required={true}
                                        profileImg={instituteLogo}
                                        imageLoader={imageLoader}
                                        profileImageDelete={setInstituteLogo}
                                        errorMsg={`${isFormSubmitted && !instituteLogo ? "Please Upload Logo" : ""}`}
                                        uploadBtnText={"Upload Logo"}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </FormBuilder>
                <div className="form-footer border-top border-primary py-3 text-end mt-4">
                    {!isFormEdit && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => { handleSubmit() }}>Save & Next</a>}
                    {isFormEdit && <a className="btn btn-primary btn-sm px-5 rounded-12 cursor-pointer" onClick={() => { handleUpdate() }}>Update</a>}
                    <Link className="text-dark text-decoration-none ms-2" to="/dashboard/institute/list">Cancel</Link>
                </div>
            </div>}
        </div>
    )
}


const mapStateToProps = (state: any) => {
    return {
        UtilityRed: state.AppUtilityReducer
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updatePopup: (data: PopupData) => dispatch(AppUtilityActions.updatePopup(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstituteFormPage);