import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { GET_STUDENT_SUBSCRIPTION_PRICE_LIST } from "../../../app/service/subscription.service";
import { DataTable } from '../../../components/data-table'
import { SubscriptionGridCols } from "./data-grid-cols";

function SubScription() {
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [subsriptionList, setSubscriptionList] = useState<any>([]);
    const [activePage, setActivePage] = useState(1);
    const [pageArray, setPageNumbers] = useState([]);
    const search = useLocation().search;

    const currentPageNumber: any = new URLSearchParams(search).get('page');


    useEffect(() => {

        getSubscriptionList()
    }, [])

    function getSubscriptionList() {
        const activePageNumber = currentPageNumber > 0 ? (currentPageNumber - 1) : 0;
        setLoading(true);
        GET_STUDENT_SUBSCRIPTION_PRICE_LIST("SUBSCRIPTION_PRICE", activePageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    setActivePage(currentPageNumber ? currentPageNumber : 1);

                    const pageNumbers = Math.ceil(res.count / 10);
                    const pageArray: any = [];
                    for (let i = 1; i <= pageNumbers; i++) {
                        pageArray.push(i);
                    }
                    setPageNumbers(pageArray);
                    let preparedele: any[] = [];
                    for (let x = 0; x < data.length; x++) {
                        preparedele.push({
                            name: "Subscription Price",
                            price: data[x].data.subscription_price
                        })
                    }
                    setSubscriptionList(preparedele);

                    setLoading(false);
                }
            }
        });
    }


    const onPageChange = (data: any) => {

        if (data) {
            history.push(`/dashboard/subscription/list?page=${data}`);
        }
        setActivePage(data);
        const pageNumber = data - 1;
        setLoading(true);
        GET_STUDENT_SUBSCRIPTION_PRICE_LIST("SUBSCRIPTION_PRICE", pageNumber).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    let preparedele: any[] = [];
                    for (let x = 0; x < data.length; x++) {
                        preparedele.push({
                            name: "Subscription Price",
                            price: data[x].data.subscription_price
                        })
                    }
                    setSubscriptionList(preparedele);
                    setLoading(false);
                }

            }
        });
    }

    return (
        <div>
            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div className="border-primary border-top">
                    <h2 className="mt-3">Subscription</h2>

                    <div className="mt-4">
                        <DataTable TableCols={SubscriptionGridCols} tableData={subsriptionList}
                            pageNumber={onPageChange} activePageNumber={activePage} pageNumbers={pageArray}></DataTable>
                    </div>
                </div>}
        </div>
    )
}
export default SubScription;