import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { GET_MODULES_BY_USER_CODES } from "../../../../app/service/module.service";
import { toast } from "react-toastify";
import { GET_USER_USER_CODE } from "../../../../app/service/users.service";
import { GET_SUBJECT_BY_UUID } from "../../../../app/service/abhyas-subject.service";
import { CREATE_PAYMENT, CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE } from "../../../../app/service/payment-service";
import { UI_BASE_URL } from "../../../../config/constant";
import { CREATE_CCAVENUE_PAYMENT } from "../../../../app/service/ccavenue.service";
import { ADD_UPDATE_SUBSCRIPTION_PRICE } from "../../../../app/service/subscription.service";
import { CREATE_MNS_USER_ASSIGN } from "../../../../app/service/mns-service";
import AVERAGEAREA from "../../../../institute-dashboard-assets/images/Average area.gif"
import WEAKAREA from "../../../../institute-dashboard-assets/images/Weak area.gif"
import GOODAREA from "../../../../institute-dashboard-assets/images/Good area.gif"
import BESTAREA from "../../../../institute-dashboard-assets/images/Best area.gif"
import LOWAREA from "../../../../institute-dashboard-assets/images/Lowest area.gif"
import FASTAREA from "../../../../institute-dashboard-assets/images/Fastest area.gif"
const ParentViewStudentParikshaTestAnalysis = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [testLoading, setTestLoading] = useState<boolean>(false);
    const [resultInfo, setResultInfo] = useState<any>({});
    const [resultData, setResultData] = useState<any>([]);
    const [selectedTest, setSelectedTest] = useState("");
    const [subjectData, setSubjectData] = useState<any>({});
    let { moduleUUID, userCode, schoolCode } = useParams<{ moduleUUID: string, userCode: string, schoolCode: string }>();
    let selectUuids = JSON.parse(localStorage.getItem('userSelectedSubjectInfo') || '{}');
    const testInfo: any = JSON.parse(localStorage.getItem("pariksha_test_info") || '{}');
    const testData: any = JSON.parse(localStorage.getItem("result-test-data") || '{}');
    const testUuid = localStorage.getItem("testUuid");
    const selectTest = localStorage.getItem("select_test");
    const history = useHistory();
    const [paymentInfo, setPaymentInfo] = useState<any>({});
    const [studentInfo, setStudentInfo] = useState<any>({});
    const [moduleData, setModuleData] = useState<any>({});
    const [canShowPaymentPopup, setCanShowPaymentPopup] = useState<boolean>(false);
    const [totalQuestions, setTotalQuestions] = useState<number>(0);
    const [attempted, setAttempted] = useState<number>(0);
    const [totalCorrect, setTotalCorrect] = useState<number>(0);
    const [totalWrong, setTotalWrong] = useState<number>(0);
    const [score, setScore] = useState<number>(0);
    const [totalWorksheets, setTotalWorksheets] = useState<number>(0);
    const [activeCardIndex, setActiveCardIndex] = useState<number | null>(null);
    const testAreas: any = [
        {
            name: "weak",
            img_src: WEAKAREA,
            bg_color: "wek"
        },
        {
            name: "average",
            img_src: AVERAGEAREA,
            bg_color: "avg"
        },
        {
            name: "good",
            img_src: GOODAREA,
            bg_color: "gd"
        },
        {
            name: "best",
            img_src: BESTAREA,
            bg_color: "bst"
        },
        {
            name: "slowest",
            img_src: LOWAREA,
            bg_color: "sw"

        },
        {
            name: "fastest",
            img_src: FASTAREA,
            bg_color: "ft"

        }
    ]
    useEffect(() => {
        const splitLocation: any = window.location.href.split("?");
        const queryString = splitLocation[1];
        if (queryString) {
            const match = queryString.match(/status=([^&]*)/);
            if (match) {
                const statusValue = match[1];
                const studentInfo: any = JSON.parse(localStorage.getItem('cc_student_info') || '{}');
                const studentPaymentInfo = JSON.parse(localStorage.getItem('cc_user_payment_info') || '{}');
                const txnId = localStorage.getItem("cc_transaction_id");
                const moduleInfo = JSON.parse(localStorage.getItem('cc_module_info') || '{}');
                if (txnId && statusValue === "success") {
                    updateSubscription(txnId, "", studentPaymentInfo, statusValue, studentInfo, moduleInfo);
                } else {
                    removeItemsFromeLocalStorage();
                }
            } else {
            }
        }
        getUserDetails();
        getModuleData();

        setLoading(true);
        getSubjectInfo();
        setResultInfo(testData);
        setTimeout(() => {
            setLoading(false);
        }, 500);

    }, []);
    function getModuleData() {
        GET_MODULES_BY_USER_CODES(userCode).then((res: any) => {

            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                } else {
                    const data = res.filter((el: any) => el.module_code === 'ABHYAAS');
                    setModuleData(data[0]);
                }
            }
        });
    }
    function getUserDetails() {
        GET_USER_USER_CODE(userCode).then((response: any) => {
            if (response.status === 'fail') {
                toast.error(response.message);
            } else {
                const data = response.rows[0];
                localStorage.setItem("cc_student_info", JSON.stringify(data));
                setStudentInfo(data);
            }
        });
    }
    const handleTestClick = (index: number, testType: any) => {
        setActiveCardIndex(index === activeCardIndex ? null : index);
        setSelectedTest(testType);
        let prepardResult = resultInfo?.result_analysis;
        switch (testType) {
            case 'weak':
                setResultData(prepardResult?.weak_area);
                totalCountData(prepardResult?.weak_area);
                break;
            case 'average':
                setResultData(prepardResult?.average_area);
                totalCountData(prepardResult?.average_area);
                break;
            case 'good':
                setResultData(prepardResult?.good_area);
                totalCountData(prepardResult?.good_area);
                break;
            case 'best':
                setResultData(prepardResult?.best_area);
                totalCountData(prepardResult?.best_area);
                break;
            case 'slowest':
                setResultData(prepardResult?.slowest_area);
                totalCountData(prepardResult?.slowest_area);
                break;
            case 'fastest':
                setResultData(prepardResult?.fastest_area);
                totalCountData(prepardResult?.fastest_area);
                break;
            default:
                setResultData([]);
        }
        setTestLoading(true);
        setTimeout(() => {
            setTestLoading(false);
        }, 500);
    };
    const totalCountData = (sum: any) => {
        let totalQuestion = 0;
        let totalCorrect = 0;
        let totalWrong = 0;
        let totalWorksheets = 0;
        let score = 0;
        let attempted = 0;
        for (let i = 0; i < sum?.length; i++) {
            totalQuestion += sum[i]?.questions;
            totalCorrect += sum[i]?.correct;
            totalWrong += sum[i]?.wrong;
            totalWorksheets += sum[i]?.worksheets;
            score += sum[i]?.percentage;
            attempted += sum[i]?.attempted;
        }
        setTotalQuestions(totalQuestion);
        setTotalWrong(totalWrong);
        setTotalCorrect(totalCorrect);
        setTotalWorksheets(totalWorksheets);
        setAttempted(attempted);
        let preparedScore = (score / parseInt(sum?.length))
        setScore(preparedScore);
    }
    const getSubjectInfo = () => {
        GET_SUBJECT_BY_UUID(selectUuids?.subject_uuid).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                if (res.rows.length > 0) {
                    setSubjectData(res.rows[0]);
                }
            }
        });
    }

    const replaceValues = (value: any) => {
        switch (value) {
            case "LOW":
                return "Easy";
            case "MEDIUM":
                return "Medium";
            case "HIGH":
                return "Hard";
            default:
                return value;
        }
    };
    const getTriangleColor = () => {
        // Return the class name for the triangle based on the selected test
        switch (selectedTest) {
            case 'weak':
                return 'triangle-weak'; // Use orange color for allocated
            case 'average':
                return 'triangle-avg'; // Use green color for attempted
            // Add cases for other test types if needed
            case 'good':
                return 'triangle-gd';
            case 'best':
                return 'triangle-bt';
            case 'slowest':
                return 'triangle-sw';
            case 'fastest':
                return 'triangle-ft';
            default:
                return ''; // Default color or class
        }
    }
    const calculateTrianglePosition = () => {
        switch (selectedTest) {
            case 'weak':
                return 'calc(15% - 5px)'; // Adjust this value as needed for your layout
            case 'average':
                return 'calc(27.5% - 5px)'; // Adjust this value as needed for your layout
            // Add cases for other test types if needed
            case 'good':
                return 'calc(40.5% - 5px)';
            case 'best':
                return 'calc(52.5% - 5px)';
            case 'slowest':
                return 'calc(72.5% - 5px)';
            case 'fastest':
                return 'calc(85.5% - 5px)';
            default:
                return '50%';
        }
    };
    const getBtnColor = (type: string) => {
        if (type === "bg") {
            switch (selectedTest) {
                case 'weak':
                    return '#c00000';
                case 'average':
                    return '#f29f05';
                case 'good':
                    return '#80b26b';
                case 'best':
                    return '#b6d93b';
                case 'slowest':
                    return '#2f5597';
                case 'fastest':
                    return '#ff0000';
                default:
                    return '';
            }
        }
        else if (type === "text") {
            switch (selectedTest) {
                case 'weak':
                    return 'Weak Area';
                case 'average':
                    return 'Average Area';
                case 'good':
                    return 'Good Area';
                case 'best':
                    return 'Best Area';
                case 'slowest':
                    return 'Slowest Area';
                case 'fastest':
                    return 'Fastest Area';
                default:
                    return '';
            }
        }
    }
    const getRangeValues = () => {
        switch (selectedTest) {
            case 'weak':
                return ' 50 and below 50';
            case 'average':
                return 'in between 51 and 74';
            case 'good':
                return 'in between 75 and 89';
            case 'best':
                return 'in between 90 to 100';
            default:
                return '';
        }
    }
    const onClickCancel = () => {
        setCanShowPaymentPopup(false);
    }
    const viewAbyhyaasModule = () => {
        if (moduleData?.amount_status === "COMPLETED") {
            history.push(`/parent-dashboard/view/abhyaas/summary/${moduleData?.uuid}/${userCode}/${schoolCode}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`)

        } else {
            if (moduleData?.uuid) {
                getPaymentDetailsOfUser();
            }
        }
    }
    function getPaymentDetailsOfUser() {
        const payLoad = {
            "institute_school_code": schoolCode,
            "standard": "",
            "division": "",
            "students": [{ user_code: `${userCode}` }],
            "module_uuid": moduleData?.uuid
        }
        CREATE_PAYMENT_DETAILS_SCHOOL_MODULE_CODE(payLoad).then((res: any) => {
            if (res.status === "fail") {
                toast.error(res?.message);
            } else {
                if (res) {
                    let data = res?.students;
                    if (data?.length > 0) {
                        setPaymentInfo(data[0]);
                        localStorage.setItem("cc_user_payment_info", JSON.stringify(data[0]));
                        setCanShowPaymentPopup(true);
                    }
                }
            }
        });

    }
    const handlePayment = () => {
        createPayment(moduleData, paymentInfo);
        localStorage.setItem("cc_module_info", JSON.stringify(moduleData));
    }
    function createPayment(moduleInfo: any, paymentInfo: any) {
        const payload = {
            school_code: schoolCode,
            user_ids: paymentInfo?.user_code,
            sub_amount: paymentInfo?.total_amount
        }
        if (parseInt(paymentInfo?.total_amount) > 0) {
            CREATE_PAYMENT(payload).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.message);
                    } else {
                        onOpenPaymentInfo(payload.sub_amount, res.txn_id, moduleInfo, paymentInfo);
                    }
                }
            });
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }
    function onOpenPaymentInfo(amount: any, txn_id: string, moduleInfo: any, paymentInfo: any) {
        localStorage.setItem("cc_user_payment_info", JSON.stringify(paymentInfo));
        localStorage.setItem("cc_transaction_id", txn_id)
        createCcavenuePayment(amount, txn_id);
    }
    const createCcavenuePayment = (amount: any, txn_id: string) => {
        let payLoad = {
            amount: amount,
            currency: "INR",
            transaction_uuid: txn_id,
            redirect_url: `${UI_BASE_URL}/parent-dashboard/view/pariksha/test-analysis/${moduleUUID}/${userCode}/${schoolCode}`,
            cancel_url: `${UI_BASE_URL}/parent-dashboard/view/pariksha/test-analysis/${moduleUUID}/${userCode}/${schoolCode}`,
        }
        CREATE_CCAVENUE_PAYMENT(payLoad).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res?.error);
            } else {
                initiatePayment(res);

            }
        });
    }
    const initiatePayment = async (paymentData: any) => {
        window.location.href = paymentData.url;
    };
    function updateSubscription(transactionId: any, transactionData: any, paymentInfo: any, paymentStatus: any, studentInfo?: any, moduleInfo?: any) {
        if (paymentInfo?.subscription_payment_status === 'pending') {
            const payload = {
                data: [
                    {
                        "uuid": "",
                        "school_code": studentInfo?.institute_school_code,
                        "user_code": paymentInfo?.user_code,
                        "transaction_id": transactionId,
                        "amount": parseInt(paymentInfo?.subscription_price),
                        // "transaction_details": JSON.stringify(transactionData),
                        "transaction_details": "",
                        "payment_status": "completed"
                    }
                ]
            }
            if (paymentStatus === "success") {
                ADD_UPDATE_SUBSCRIPTION_PRICE(payload).then((res: any) => {
                    if (res.status === 'fail') {
                        toast.error(res?.message);
                    } else {
                        getAssignModule(paymentInfo, moduleInfo, studentInfo);
                        toast.success('Subscription Added Successfully');
                    }
                });
            }
        } else {
            getAssignModule(paymentInfo, moduleInfo, studentInfo);
        }
    }

    function getAssignModule(paymentInfo: any, moduleData: any, studentInfo?: any) {
        const user_code = [];
        user_code.push(paymentInfo?.user_code);
        const payload = {
            module_uuid: moduleData.uuid ? moduleData.uuid : '',
            payment_status: "COMPLETED",
            school_code: studentInfo?.institute_school_code,
            target_std: moduleData.target_std,
            total_amount_paid: paymentInfo?.module_price,
            user_codes: user_code
        }
        CREATE_MNS_USER_ASSIGN(payload).then((res: any) => {
            if (res.status === 'fail') {
                toast.error(res.message);
            } else {
                toast.success("Successfully assigned  module!");
                getModuleData()
                removeItemsFromeLocalStorage();
            }
        });
    }

    const removeItemsFromeLocalStorage = () => {
        const keysToRemove = ['cc_student_info', 'cc_user_payment_info', 'cc_transaction_id', 'cc_module_info'];
        keysToRemove.forEach((key: any) => {
            localStorage.removeItem(key);
        });
    };
    const getWorksheetCount = (resultData: any) => {
        let preData = resultData?.every((item: any) => item?.worksheets == 0)
        return preData
    }
    return (
        <div>
            <div
                className={`modal fade ${canShowPaymentPopup ? "show" : "false"}`}
                style={{ display: canShowPaymentPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog pariksha-model-dialog" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  pariksha-model-info">
                            <div className='pariksha-model-border ba bas-dashed'>
                                <div className=' mt-4 justify-content-center'>
                                    <h6 className='blue_text f16'>You do not have to access to <br />ABHYAAS initiative to solve worksheets </h6>
                                </div>
                                <p className='text-center blue_text fw-normal'>You need to pay  <i className="fa fa-rupee pt-3"></i> {paymentInfo?.total_amount}.00  to access <br /> ABHYAAS initiative </p>
                                <div className='start-test mt-4 justify-content-center'>
                                    <h6>Do you want to continue ? </h6>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn test-btn' onClick={handlePayment}>Pay Now</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <nav aria-label="breadcrumb">

                        {testUuid !== "0" && <ol className="breadcrumb pb-2 ps-0 pt-0 border-bottom">
                            <li className="breadcrumb-item "><Link to="/parent-dashboard/view"><span className='pe-2'>Dashboard</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/parent-dashboard/view/pariksha/initiative/${moduleUUID}/${userCode}/${selectUuids?.school_code}`}><span>Pariksha</span></Link></li> /
                            <li className="breadcrumb-item "><Link to={`/parent-dashboard/view/pariksha/summary/${moduleUUID}/${userCode}/${selectUuids?.school_code}/${selectUuids?.board_uuid}/${selectUuids?.medium_uuid}/${selectUuids?.standard_uuid}/${selectUuids?.subject_uuid}`}><span>{subjectData?.name?.charAt(0).toUpperCase() + subjectData?.name?.slice(1).toLowerCase()}</span></Link></li> /
                            <li className="breadcrumb-item "><span>{selectTest} tests</span></li> /
                            <li className="breadcrumb-item "><span>Analysis</span></li>
                        </ol>}
                    </nav>
                    <div className='text-end me-5 mb-1'>
                        <button className="btn btn-primary btn-sm px-5 py-1 rounded-12 cursor-pointer" onClick={() => history.goBack()}>Back</button>
                    </div>
                    <h5 className="text-center mt-3">Test Analysis :{resultInfo?.exam_type && <span className='text-black'> {resultInfo?.exam_type?.split('_')
                        .map((word: any) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase())
                        .join(' ')}   {(resultInfo?.exam_type === "CHAPTER_WISE_TEST") && <span>({resultInfo?.test_details?._chapter?.name})</span>}</span>}</h5>

                    <div className={`mb-5 position-relative test_area text-center  pt-md-4  ${!selectedTest ? "mt-5" : "mt-3"}`} >
                        {testAreas?.map((test: any, i: number) => (
                            <>
                                <div key={i} className={`mt-4 area_bx cursor-pointer ${selectedTest === test?.name ? 'selected' : ''}`} onClick={(event) => handleTestClick(i, test?.name)}>
                                    <div className="sg_bd">
                                        <img src={test?.img_src} className={` ${test?.bg_color === "ft" ? "analysis_ft_area" : "analysis_area"}`} alt={`${test} area`} />
                                    </div>
                                    <p className={`mb-0 f16 ${test?.bg_color}_bg text-white`}>{test?.name?.charAt(0).toUpperCase() + test?.name.slice(1)} <br />Area</p>
                                    <h5 className={`mt-4 ${test?.bg_color}_text fw-bold f22`}>{resultInfo?.result_analysis?.[`${test?.name}_area_percentage`]?.toFixed()}%</h5>
                                    {(selectedTest === test?.name && activeCardIndex !== null) && <div className={`triangle_analysis ms-3 ${getTriangleColor()}`} ></div>}
                                    {(selectedTest === test?.name && activeCardIndex !== null) &&
                                        <div className="test_popup-table-container" >
                                            <div className="bg-white border border-2 px-3 pb-3  pb-lg-5  px-lg-3  mx-md-3 mb-5">
                                                <div className='text-center mt-5'><button className='test_sm_btn mb-2' style={{ backgroundColor: getBtnColor("bg") }}>{getBtnColor("text")}
                                                </button>
                                                    {getRangeValues() && <p className="mb-0">( It includes the topic of the test in which you have scored  {getRangeValues()} )</p>}
                                                    {selectedTest === 'slowest' && <p className="mb-0">( It includes of the test in which you have utilize the more time than average time for a question )</p>}
                                                    {selectedTest === 'fastest' && <p className="mb-0">( It includes of the test in which you have utilize the less time than average time for a question )</p>}
                                                </div>
                                                {testLoading &&
                                                    <div className="text-center p-5">
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                }
                                                {!testLoading &&
                                                    <div>
                                                        <div className="row align-items-center  px-2 ">
                                                            <div className="col-md-12 col-12 ps-lg-3">
                                                                <div>
                                                                    <div className="mt-3 custom-scroll table-responsive ">
                                                                        <table className="table ability-table mb-0">
                                                                            <thead>
                                                                                <tr className='ability-topic-header'>
                                                                                    <th>Topic Name</th>
                                                                                    <th className='text-center'>Questions</th>
                                                                                    <th className='text-center'>Attempted</th>
                                                                                    <th className='text-center'>Correct</th>
                                                                                    <th className='text-center'>Wrong</th>
                                                                                    <th className='text-center'>Score %</th>
                                                                                    <th className='text-center'>Wroksheets</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {resultData?.map((item: any, i: number) => (
                                                                                    <React.Fragment key={i}>
                                                                                        <tr>
                                                                                            <td className={`${item?.worksheets == 0 && "text-completed"}`}>{item?.topic_name}</td>
                                                                                            <td className='text-center'>{item?.questions}</td>
                                                                                            <td className='text-center'>{item?.attempted}</td>
                                                                                            <td className='text-center'>{item?.correct}</td>
                                                                                            <td className='text-center'>{item?.wrong}</td>
                                                                                            <td className='text-center'>{item?.percentage?.toFixed(0)}</td>
                                                                                            <td className='text-center'>{item?.worksheets}</td>
                                                                                        </tr>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                                {resultData?.length > 0 && <tr className='border-bottom border-white' >
                                                                                    <td colSpan={1} className="text-center text-black fw-bold" scope="col">Total</td>
                                                                                    <td className='text-black fw-bold text-center'>{totalQuestions}</td>
                                                                                    <td className='text-black fw-bold text-center'>{attempted}</td>
                                                                                    <td className='text-black fw-bold text-center'>{totalCorrect}</td>
                                                                                    <td className='text-black fw-bold text-center'>{totalWrong}</td>
                                                                                    <td className='text-black fw-bold text-center'>{score?.toFixed(0)}</td>
                                                                                    <td className='text-black fw-bold text-center'>{(totalWorksheets == 0 || totalWorksheets > 0) && totalWorksheets}</td>
                                                                                </tr>}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    {resultData?.length == 0 && <p className='text-center f16 mt-2'>No Data Available</p>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(resultData?.length > 0 && !getWorksheetCount(resultData)) && <div className="text-end">
                                                            <button className='btn btn-primary f14 mt-4' onClick={viewAbyhyaasModule}>GO FOR ABHYAAS</button>
                                                        </div>}
                                                    </div>}
                                            </div>
                                        </div>}
                                </div>
                                {i == 3 && <div className="analysis_vl mt-4 d-none d-lg-block mt-5 "></div>}
                            </>))}
                    </div>
                </div>}
        </div>
    )
}
export default ParentViewStudentParikshaTestAnalysis;