import React, { useEffect, useRef, useState } from 'react'
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { GET_MODULES_BY_SCHOOL_ID, GET_TOTAL_MODULES, UPDATE_ASSIGN_USER } from '../../../../app/service/module.service';
import { CREATE_PAYMENT, UPDATE_PAYMENT } from '../../../../app/service/payment-service';
import FormBuilder from '../../../../components/form-builder';
import { FormControlError, FormField, FormValidators } from '../../../../components/form-builder/model/form-field';
import appConfig, { UI_BASE_URL } from '../../../../config/constant';
import { FormValidator, GetControlIsValid } from '../../../../components/form-builder/validations';
import { MultiSelect } from 'react-multi-select-component';
import { GET_STUDENTS_BY_PAYMENT_MODULE, GET_STUDENTS_BY_STANDARD_DIVISION, GET_USERS_BY_SCHOOL_CODE } from '../../../../app/service/users.service';
import { CREATE_MNS_USER_ASSIGN } from '../../../../app/service/mns-service';
import { ADD_UPDATE_SUBSCRIPTION_PRICE, GET_SUBSCRIPTION_PRICE_STATUS_BY_SCHOOL_STUDENTS } from '../../../../app/service/subscription.service';
import moment from 'moment';
import { APPLY_COUPON_CODE, UPDATE_PROMO_CODES } from '../../../../app/service/promotions';
import { GET_SCHOOL_BY_ID, GET_SCHOOL_BY_SCHOOL_CODE } from '../../../../app/service/school.service';
import axios from 'axios';
import { CREATE_CCAVENUE_PAYMENT } from '../../../../app/service/ccavenue.service';
import { decrypt, encrypt } from '../../../../app/utility/ccVenueUtils';
import { useHistory, useLocation } from 'react-router-dom';


interface PaymentData {
    amount: number;
    orderId: string;
    email: string;
    // Add other payment-related fields here
}

export const PaymentList = () => {
    const [paymentList, setPaymentList] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [payment, setPayment] = useState(false);
    const [paymentStudentList, setPaymentStudentList] = useState(false);
    let { id, schoolCode } = useParams<{ id: string, schoolCode: string }>();
    const schoolsCode = schoolCode;
    const history = useHistory();


    useEffect(() => {
        getPayments();
        const accessObjectsData: any = localStorage.getItem("access_objects_data");
        onHandleTable(JSON.parse(accessObjectsData));
    }, []);



    function getPayments() {
        setLoading(true);
        GET_MODULES_BY_SCHOOL_ID(schoolsCode).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res.message);
                    setLoading(false);
                } else {
                    const data = res.rows;
                    const moduleData = data.filter((el: any) => el.module_code !== 'CAS-PARENT');
                    setPaymentList(moduleData);
                    setLoading(false);
                }
            }
        });
    }



    function onClickPay(data?: any) {
        // history.push(`/dashboard/school/${id}/${schoolsCode}/payment/list`);

        history.push(`/dashboard/school/${id}/${schoolsCode}/${data.module_uuid}/payment/list`);
    }



    function onHandleTable(accessObjectsList: any) {
        const preparedData = accessObjectsList?.filter(
            (el: any) => el.object_code === "SCHOOLS"
        );
        if (preparedData?.length > 0) {
            for (let i = 0; i < preparedData[0].child.length; i++) {
                if (
                    preparedData[0].child[i].object_code === "SCHOOLS-PAYMENT" &&
                    preparedData[0].child[i].p_read === 1
                ) {
                    setPayment(true);
                }
                if (
                    preparedData[0].child[i].object_code === "SCHOOLS-PAYMENT-SELECT-STUDENT-LIST" &&
                    preparedData[0].child[i].p_read === 1
                ) {
                    setPaymentStudentList(true);
                }
            }
        }
    }

    return (
        <div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading && <div className="mar-t-20 custom-scroll table-responsive">
                {payment && <table className="table fixed-table-header mb-0">
                    <thead>
                        {/* <tr>
                            <th >Membership</th>
                            <th>
                                3650 <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickPay()}>Select Student List</button>
                            </th>
                        </tr> */}
                        <tr>
                            <th>Sr.No</th>
                            <th>Module Name</th>
                            <th>Price / Student</th>
                            {paymentStudentList && <th>Action</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {/* <tr>

                            <td colSpan={3}>Memebrship </td>
                            <td>
                                <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" >Select Student List</button>
                            </td>
                        </tr> */}
                        {paymentList.map((name: any, i: number) => (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                <td>{name.module_code}</td>
                                <td>{name.amount}</td>
                                {paymentStudentList && <td>
                                    <button className="file btn btn-sm btn-primary px-4 rounded-12 d-inline-block cursor-pointer" onClick={() => onClickPay(name)}>Select Student List</button>
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                </table>}
            </div>}
            {paymentList.length === 0 && <div style={{ textAlign: 'center' }}>
                No records Found
            </div>}
        </div>
    )
}
